import React, { Component, useEffect, useState } from "react";
import banerimg10 from "../assets/images/bannerimg.png";
import LOGOf from "../assets/images/LogoFreeCrop.png";
import Swal from "sweetalert2";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { navigate } from "@reach/router";

function DialogModal(openOrNot) {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [number, setNumber] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrMsg, setNumberErrMsg] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [locationErrMsg, setLocationErrMsg] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenModal(true);
    }, 18000);
  }, []);
  const modalCloseButton = () => {
    setOpenModal(false);
  };

  const Testing = (successText) => {
    const Toast = Swal.mixin({
      position: "center",
      showConfirmButton: false,
      timer: 4000,

      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      background: "white",
      iconColor: "green",
      color: "green",
      title: successText,
    });
  };
  const valid = () => {
    if (name.length < 2) {
      setNameError(true);
      setNameErrMsg("This is a mandatory field, please fill");
      return false;
    }
    if (emailId.length < 2) {
      setEmailError(true);
      setEmailErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (number.length < 2) {
      setNumberError(true);
      setNumberErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (location.length < 2) {
      setLocationError(true);
      setLocationErrMsg("This is a mandatory field, please fill");
      return false;
    } else {
      return true;
    }
  };
  const submitMailDetails = () => {
    setNumberError(false);
    setNameError(false);
    setEmailError(false);
    setLocationError(false);
    console.log("name", name.length);
    if (valid()) {
      setLoading(true);
      const payload = {
        name: name,
        email: emailId,
        phone: number,
        location: location,
      };
      axios
        .post("https://ihminfo.co.in/backend/MasterApi/saveInfo", payload)
        .then((response) => {
          setLoading(false);
          setName("");
          setEmailId("");
          setNumber("");
          setLocation("");
          console.log(response.data.result);
          if (response.data.result === 1) {
            setSuccessMsg(response.data.message);
            navigate("/thank_you");
            // Testing(response.data.message);
          }
        });
    } else {
    }
  };

  return (
    <>
      {openModal ? (
        <section id="cuspanel2" className="vh-100 popuopz lunch-popup lunch-popup2">
          <div className="container  h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-10 mb-5">
                <div className="card" style={{ borderRadius: "1rem" }}>
                  <button
                    onClick={modalCloseButton}
                    className="closebox"
                  ></button>
                  <div className="row g-0">
                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                      <img
                        src={banerimg10}
                        alt="login form"
                        className="img-fluid"
                        style={{
                          borderRadius: "1rem 0 0 1rem",
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                      <div className="card-body p-4 p-lg-5 text-black">
                        <form>
                          <div className="d-flex align-items-center mb-3 pb-0">
                            {/* <a className="pop-logo" href="#">
                              <img src={LOGOf} alt="logo" />
                            </a> */}
                            <span className="h2 fw-bold mb-0">IHMs India</span>
                          </div>

                          <h5
                            className="fw-normal mb-2 pb-3"
                            style={{ letterSpacing: "1px" }}
                          >
                            TO GET MORE INFO PLEASE CONTACT
                          </h5>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Name
                            </label>
                            <input
                              type="text"
                              id="form2Example27"
                              value={name}
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              required
                            />
                            {nameError ? (
                              <span className="fromerror modalv2">
                                {nameErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example17">
                              Email address
                            </label>
                            <input
                              type="email"
                              id="form2Example17"
                              value={emailId}
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setEmailId(e.target.value);
                              }}
                            />
                            {emailError ? (
                              <span className="fromerror modalv2">
                                {emailErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Phone No.
                            </label>
                            <input
                              type="number"
                              id="form2Example27"
                              value={number}
                              maxlength="10"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setNumber(e.target.value);
                              }}
                            />
                            {numberError ? (
                              <span className="fromerror modalv2">
                                {numberErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Location
                            </label>
                            <input
                              type="text"
                              id="form2Example27"
                              value={location}
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setLocation(e.target.value);
                              }}
                            />
                            {locationError ? (
                              <span className="fromerror modalv2">
                                {locationErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="pt-1 mb-2">
                            {/* <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        href="mailto:someone@example.com"
                      >
                        {" "}
                        <p> office@sermaps.com</p>
                      </a> */}
                            <button
                              className="btn btn-dark btn-lg btn-block"
                              type="button"
                              onClick={submitMailDetails}
                            >
                              {loading ? (
                                <ClipLoader color="white" />
                              ) : (
                                <span>Submit</span>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}

export default DialogModal;
