import React from "react";
import { Link } from "react-router-dom";

const ExamCentres =()=> {
    return(<div className="syllabus-container">
        <h3 className="text-center">NCHMCT JEE Exam Centres 2022</h3>
        <div className="container-fluid" style={{backgroundColor: "lightgrey"}}>
<p >
    <span style={{fontWeight: "bold"}}>National Testing Agency (NTA) </span>released the list of<span style={{fontWeight: "bold"}}> NCHM JEE Exam Centres 2022</span>. NCHMCT JEE exam centres 2022 are the cities where the Joint entrance examination was conducted. NCHM JEE exam 2022 was held across forty nine cities of India for admissions to over 8,000 seats. </p>
        <p>
        Test-takers were required to report to the exam centre of NCHM JEE 2022 2 hour before the commencement of the examination. Candidates might choose at least four NCHM JEE 2022 exam centres from the required ones while filling the application form. The complete list of NCHM JEE exam centres 2022 will be checked below.
        </p>

        <h2>Choice of Cities for Exam Centre:</h2>
        <p>It is mandatory for candidates to select four cities of their choice while filling Online Application Form for NCHM JEE 2021. Effort will be made to allot Centre of Examination to the candidate in order of the City Choice opted by
them in the Application Form. However, due to logistic & administrative reasons, a different city of nearby area may be allotted.</p>
        
        <p>The City of Examination once chosen and allotted cannot be changed (after the closing of the correction window) and any request in this regard will not be entertained.

NTA will try to allocate the Examination Centre in one of the chosen Cities, which will be mentioned in the Admit Card. However, due to Administrative/Logistic reasons, the Exam Centre may be allotted in another City nearer to the present address of the Candidate.</p>
       
       <h2>NCHMCT JEE 2022 Exam Centres:</h2>
       <p>National Testing Agency conducted NCHM JEE 2022 in computer-based mode. Earlier it had been conducted by the National Council of Hotel Management and Catering Technology (NCHMCT). The list of the test centers for NCHM JEE is given below:</p>
       
       <h3>NCHMCT JEE Exam Centres:</h3>
       
       <p style={{fontWeight: "bold"}}>Below we have provided the list of Examination Cities for NCHM JEE:</p>
       
       <div className="container">
           <div className="row">
               <div className="col-md-4" style={{textAlign: "center"}}><h4 style={{textDecoration: "underline" }}>STATE</h4></div>
               <div className="col-md-8" style={{textAlign: "center"}}><h4 style={{textDecoration: "underline" }}>CITY</h4></div>
           </div>
           <div className="row">
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
ANDHRA
PRADESH</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>VIJAYAWADA</h5><h5>
               VISAKHAPATNAM</h5><h5>
               TIRUPATI</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               ASSAM</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>GUWAHATI</h5><h5>
                   TEJPUR</h5><h5>
                   MUZAFFARPUR</h5><h5>GAYA</h5><h5>DARBHANGA</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               CHANDIGARH</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>BARAUNI/BEGUSARAI</h5><h5>
                   PATNA</h5><h5>
                   CHANDIGARH / PANCHKULA</h5><h5>BHILAI/DURG</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               CHHATTISGARH</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>BILASPUR</h5><h5>
                   RAIPUR</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               DELHI</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>DELHI/NEW DELHI</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               GOA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>PANAJI/MADGAON</h5></div>
           </div>




           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               GUJARAT</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>AHMEDABAD/GANDHINAGAR</h5><h5>
                   SURAT</h5><h5>
                   VADODARA</h5><h5>FARIDABAD</h5></div>
           </div>




           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               HARYANA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>AMBALA</h5><h5>
                   GURUGRAM</h5><h5>
                   HAMIRPUR</h5></div>
           </div>




           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               CHANDIGARH</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>BARAUNI/BEGUSARAI</h5><h5>
                   PATNA</h5><h5>
                   CHANDIGARH / PANCHKULA</h5><h5>BHILAI/DURG</h5></div>
           </div>




           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               HIMACHAL PRADESH</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>SHIMLA</h5><h5>
                   DHARAMSHALA/KANGRA</h5></div>
           </div>




           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               JAMMU & KASHMIR</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>JAMMU</h5><h5>
                   SRINAGAR(J&K)</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               JHARKHAND</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>JAMSHEDPUR</h5><h5>
                   DEVGHAR</h5><h5>
                   RANCHI</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               KARNATAKA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>BENGALURU</h5><h5>
                   MENGALORE</h5><h5>
                   HASSAN</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               KERALA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>ERNAKULAM/ANGAMALY
/MOOVATTU PUZHA</h5><h5>
THIRUVANANTHAPURAM</h5><h5>
PALAKKAD</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               MADHYA PRADESH</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>BHOPAL</h5><h5>
                   INDORE</h5><h5>
                   GWALIOR</h5><h5>JABALPUR</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               MAHARASHTRA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>MUMBAI/NAVI MUMBAI</h5><h5>
                   NAGPUR</h5><h5>
                   NASHIK</h5><h5>PUNE</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               MEGHALAYA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
               <h5>
                   SHILLONG</h5></div>
           </div>






           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               MANIPUR</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>IMPHAL</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               ODISHA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>BHUBANESWAR</h5><h5>
                   BEHRAMPUR</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               PUNJAB</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>AMRITSAR</h5><h5>
                   BHATINDA</h5><h5>
                   JALANDHAR</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               RAJASTHAN</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>JAIPUR</h5><h5>
                   KOTA</h5><h5>
                   UDAIPUR</h5><h5>JODHPUR</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               TAMIL NADU</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>CHENNAI</h5><h5>
                   MADURAI</h5><h5>
                   COIMBATORE</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               PUDDUCHERRY</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>
                   PUDDUCHERRY</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               TELANGANA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>
                   HYDERABAD</h5><h5>  WARANGAL</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
             TRIPURA</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>
                   AGARTALA</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               UTTAR
PRADESH</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>
                   ALLAHABAD/
PRAYAGRAJ</h5><h5>NOIDA/
GREATER NOIDA</h5><h5>LUCKNOW</h5><h5>KANPUR</h5><h5>SAHARANPUR</h5><h5>GORAKHPUR</h5>
<h5>VARANASI</h5></div>
           </div>
           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               UTTARAKHAND</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>
                   DEHRADUN</h5><h5>NAINITAL</h5><h5>NEW TEHRI</h5></div>
           </div>

           <div className="row" style={{borderTop: "1px solid black"}}>
               <div className="col-md-4" style={{textAlign: "center"}}> <h5>
               WEST
BENGAL</h5>
</div>
               <div className="col-md-8" style={{textAlign: "center"}}>
                   <h5>
                   KOLKATA</h5><h5>SILIGURI</h5><h5>MALDA</h5></div>
           </div>

       </div>
      
        </div>
        <h4>Note: 1. Due to administrative/Logistic reasons or incase less number of Candidates opting a City for their Exam City in this list, City may be merged with a nearby City for the purpose of allotment of Exam Centre(s) to concened Candidates.
 In such case, the Candidate will be intimated through their respective Admit Cards only.</h4>
<h3>
NCHMCT JEE Exam Centres 2022 Important Points:
</h3>
<p>In case a candidate is found using unfair suggests that or impersonating, then his/her candidature is liable to be canceled.
Carrying any type of textual material, calculators, slide rules, electronic watches having calculator, DocuPen, log tables, papers, mobile phone, pager, or the other device wasn’t allowed. Candidates might bring solely the admit card to the test center of NCHMCT JEE 2022.</p>
   
<a href="/nchmct-study-material"><p>You May also want to know about NCHMCT Study materials</p></a>
<a href="/nchmct-syllabus"><p>You May also want to know about NCHMCT Syllabus</p></a>
<a href="/nchmct-jee-exam"><p>You May also want to know about NCHMCT JEE</p></a>

    </div>)
}
 export default ExamCentres