import React, { Component, useState } from "react";
import bannerimg1 from "../assets/images/sliderImages/IHM-Kolkata.jpg";
import bannerimg2 from "../assets/images/bannerimg1.png";
import bannerimg3 from "../assets/images/bannerimg2.png";
import bannerimg4 from "../assets/images/bannerimg3.png";
import bannerimg5 from "../assets/images/bannerimg4.png";
import bannerimg6 from "../assets/images/bannerimg5.png";
import bannerimg7 from "../assets/images/sliderImages/IHM-Kolkata.jpg";
import bannerimg8 from "../assets/images/sliderImages/IHM-Bhopal.jpg";
import bannerimg9 from "../assets/images/IHM-Kovalam.jpg";
import pusha from "../assets/images/IHM-Pusa.jpg";
import Hyderabad from "../assets/images/IHM-Hyderabad.jpg";
import mumbai from "../assets/images/IHM-Mumbai.jpg";
import Lucknow from "../assets/images/IHM-Lucknow.jpg";
import Chennai from "../assets/images/IHM-Chennai.jpeg";
import bannerimg10 from "../assets/images/IHM-Shimla-Front.jpg";
import Chandigarh from "../assets/images/IHM-Chandigarh.jpg";
import Carousel from "react-elastic-carousel";
import DialogModal from "./dialogModal";
import banerimg10 from "../assets/images/bannerimg.png";
import Swal from "sweetalert2";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import LOGOf from "../assets/images/LogoFreeCrop.png";
import { navigate } from "@reach/router";

function HomeSlider() {
  const [modalShow, setModalShow] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [number, setNumber] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrMsg, setNumberErrMsg] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [locationErrMsg, setLocationErrMsg] = useState(false);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
  ];

  // function DialogModal() {

  //   const openModal =()=>  {
  //    setOpenModal(false)
  //   }
  const OpenModal = () => {
    setModalShow(true);
  };
  console.log("modal", modalShow);

  const Testing = (successText) => {
    const Toast = Swal.mixin({
      position: "center",
      showConfirmButton: false,
      timer: 4000,

      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      background: "white",
      iconColor: "green",
      color: "green",
      title: successText,
    });
  };
  const valid = () => {
    if (name.length < 2) {
      setNameError(true);
      setNameErrMsg("This is a mandatory field, please fill");
      return false;
    }
    if (emailId.length < 2) {
      setEmailError(true);
      setEmailErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (number.length < 2) {
      setNumberError(true);
      setNumberErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (location.length < 2) {
      setLocationError(true);
      setLocationErrMsg("This is a mandatory field, please fill");
      return false;
    } else {
      return true;
    }
  };
  const submitMailDetails = () => {
    setNumberError(false);
    setNameError(false);
    setEmailError(false);
    setLocationError(false);
    console.log("name", name.length);
    if (valid()) {
      setLoading(true);
      const payload = {
        name: name,
        email: emailId,
        phone: number,
        location: location,
      };
      axios
        .post("https://ihminfo.co.in/backend/MasterApi/saveInfo", payload)
        .then((response) => {
          setLoading(false);
          setName("");
          setEmailId("");
          setNumber("");
          setLocation("");
          console.log(response.data.result);
          if (response.data.result === 1) {
            setSuccessMsg(response.data.message);
            navigate("/thank_you");
            // Testing(response.data.message);
          }
        });
    } else {
    }
  };

  return (
    <div className="University-slider2">
      <div id="carocla" className="section-inner container-md mt-10">
        <div className="row">
          <div className="col-12">
            <Carousel breakPoints={breakPoints} enableAutoPlay>
              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={bannerimg7} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Kolkata
                          <div className="rank">
                            10
                            <span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1963
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={bannerimg8} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Bhopal
                          <div className="rank">
                            11
                            <span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1978
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={bannerimg9} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Kovalam
                          <div className="rank">
                            13
                            <span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1990
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={bannerimg10} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Shimla
                          <div className="rank">
                            14
                            <span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1984
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={pusha} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Pusa
                          <div className="rank">
                            1<span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1962
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .{" "}
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={mumbai} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Mumbai
                          <div className="rank">
                            3<span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1972
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={Hyderabad} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Hyderabad
                          <div className="rank">
                            4<span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1972
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={Chennai} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Chennai
                          <div className="rank">
                            5<span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1963
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={Lucknow} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Lucknow
                          <div className="rank">
                            6<span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1969
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item article-items">
                <div className="row causol-row">
                  <div className="col-sm-6  margi-s">
                    <div className="card card-img-box mr-3">
                      <img src={Chandigarh} alt="img" />
                    </div>
                  </div>
                  <div className="col-sm-6  margi-s2">
                    <div className="card customcard">
                      <div className="card-body">
                        <h5 className="card-title">
                          IHM Chandigarh
                          <div className="rank">
                            8<span>Rank</span>
                          </div>
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Est 1990
                        </h6>

                        <p className="card-text">
                          IHMs offers undergraduate, postgraduate and diploma
                          programmes to aspirants in the field of hospitality .
                        </p>
                        <button
                          type="button"
                          onClick={OpenModal}
                          className="btn btn-primary card-link kn-btn"
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      <>
        {modalShow ? (
          <section id="cuspanel2" className="vh-100 lunch-popup lunch-popup2">
            <div className="container py-5 h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col col-xl-10 mb-5">
                  <div className="card" style={{ borderRadius: "1rem" }}>
                    <button
                      onClick={() => setModalShow(false)}
                      className="closebox"
                    ></button>
                    <div className="row g-0">
                      <div className="col-md-6 col-lg-5 d-none d-md-block">
                        <img
                          src={banerimg10}
                          alt="login form"
                          className="img-fluid"
                          style={{
                            borderRadius: "1rem 0 0 1rem",
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="col-md-6 col-lg-7 d-flex align-items-center">
                        <div className="card-body p-4 p-lg-5 text-black">
                          <form>
                            <div className="d-flex align-items-center mb-3 pb-0">
                              {/* <a className="pop-logo" href="#">
                                <img src={LOGOf} alt="logo" />
                              </a> */}
                              <span className="h2 fw-bold mb-0">
                                IHMs India
                              </span>
                            </div>

                            <h5
                              className="fw-normal mb-2 pb-3"
                              style={{ letterSpacing: "1px" }}
                            >
                              TO GET MORE INFO PLEASE CONTACT
                            </h5>

                            <div className="form-outline mb-2">
                              <label
                                className="form-label"
                                for="form2Example27"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                id="form2Example27"
                                value={name}
                                className="form-control form-control-lg"
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                required
                              />
                              {nameError ? (
                                <span className="fromerror modalv2">
                                  {nameErrMsg}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="form-outline mb-2">
                              <label
                                className="form-label"
                                for="form2Example17"
                              >
                                Email address
                              </label>
                              <input
                                type="email"
                                id="form2Example17"
                                value={emailId}
                                className="form-control form-control-lg"
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                }}
                              />
                              {emailError ? (
                                <span className="fromerror modalv2">
                                  {emailErrMsg}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="form-outline mb-2">
                              <label
                                className="form-label"
                                for="form2Example27"
                              >
                                Phone No.
                              </label>
                              <input
                                type="number"
                                id="form2Example27"
                                maxlength="10"
                                value={number}
                                className="form-control form-control-lg"
                                onChange={(e) => {
                                  setNumber(e.target.value);
                                }}
                              />
                              {numberError ? (
                                <span className="fromerror modalv2">
                                  {numberErrMsg}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="form-outline mb-2">
                              <label
                                className="form-label"
                                for="form2Example27"
                              >
                                Location
                              </label>
                              <input
                                type="text"
                                id="form2Example27"
                                value={location}
                                className="form-control form-control-lg"
                                onChange={(e) => {
                                  setLocation(e.target.value);
                                }}
                              />
                              {locationError ? (
                                <span className="fromerror modalv2">
                                  {locationErrMsg}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="pt-1 mb-2">
                              {/* <a
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                      href="mailto:someone@example.com"
                    >
                      {" "}
                      <p> office@sermaps.com</p>
                    </a> */}
                              <button
                                className="btn btn-dark btn-lg btn-block"
                                type="button"
                                onClick={submitMailDetails}
                              >
                                {loading ? (
                                  <ClipLoader color="white" />
                                ) : (
                                  <span>Submit</span>
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default HomeSlider;
