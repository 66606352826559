import React from 'react'

function TableSections() {
  return (
    <div className="container">
        
<div className="row">
  <div className="col-12 centercontain containlargbox">
    <h1>Courses offered by IHMs in India  </h1>
    <p> Academics for  BSc Hospitality &amp; Hotel Administration  and ten other courses of study offered at
      IHMs are run and regulated by National Council for Hotel Management &amp; Catering Technology
      (NCHMCT) which is an autonomous body that falls under the ambit of Ministry of Tourism,
      Government of India.
      <br />
      IHMs offers undergraduate, postgraduate and diploma programmes to aspirants in the field of
      hospitality and travel as well as  baking and confectionery . Some popular hotel management
      courses offered at IHMs are mentioned in the table below:</p>
  </div>          
</div>

        <div className="row t-mar">
          <div className="col-12 containlargbox2">
            <h6 className="text-center">List of UG courses offered at IHMs</h6>            
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-md-6 mdcontainbox border-b">
            <p>BSc in Hospitality &amp; Hotel Administration</p>
          </div>
          <div className="col-md-6 mdcontainbox border-b border-r">
            <p>BSc  Culinary Arts</p>
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-12 containlargbox2">
            <h6 className="text-center">List of Diploma courses offered at IHMs</h6>            
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-md-6 mdcontainbox">
            <p>Diploma in  Food &amp; Beverage Service</p>
          </div>
          <div className="col-md-6 mdcontainbox border-r">
            <p>Diploma in Bakery &amp; Confectionery</p>
          </div>
          <div className="col-md-6 mdcontainbox">
            <p>Diploma in  Front Office Operations</p>
          </div>
          <div className="col-md-6 mdcontainbox  border-r">
            <p>Diploma in  House Keeping</p>
          </div>
          <div className="col-md-6 mdcontainbox border-b ">
            <p>Diploma in  Food Production</p>
          </div>
          <div className="col-md-6 mdcontainbox border-b border-r null-tbox">
            <p>  </p>
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-12 containlargbox2">
            <h6 className="text-center">List of PG Diploma courses offered at IHMs</h6>            
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-md-6 mdcontainbox border-b">
            <p>Post Graduate Diploma in Accommodation
              Operations &amp; Management</p>
          </div>
          <div className="col-md-6 mdcontainbox border-b border-r">
            <p>Post Graduate Diploma in  Dietetics  &amp; Hospital
              Food Service</p>
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-12 containlargbox2">
            <h6 className="text-center">List of Craftsmanship &amp; Certificate courses offered at IHMs</h6>            
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-md-6 mdcontainbox">
            <p>Craftsmanship Course in Food Production &amp;
              Patisserie</p>
          </div>
          <div className="col-md-6 mdcontainbox border-r">
            <p>Craftsmanship Course in Food &amp; Beverage
              Service</p>
          </div>
          <div className="col-md-6 mdcontainbox border-b ">
            <p>Certificate Course in Bakery</p>
          </div>
          <div className="col-md-6 mdcontainbox border-b border-r">
            <p>Certificate Course in Cookery</p>
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-12 containlargbox2">
            <h6 className="text-center">List of PG courses offered at IHMs</h6>            
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-md-6 mdcontainbox border-b">
            <p>MSc in Hospitality Administration</p>
          </div>
          <div className="col-md-6 mdcontainbox border-b border-r">
            <p>MSc in Hospitality &amp; Hotel Administration</p>
          </div>
        </div>

    
              
              {/* <a href=""   className='course-btn' type="button" target="_blank">Know More</a>  */}


        <div className="row">
          <div className="col-12 containlargbox centercontain">
            <h1>IHM Admission Process </h1>
            <p>Admissions in different courses offered at IHMs is on the basis of separate eligibility and admission
              criteria.</p>
          </div>          
        </div>
        <div className="row">
          <div className="col-12 containlargbox">
            <h1>IHM Admissions in UG Courses </h1>
            <p>Candidates are eligible to secure admission in BSc in Hospitality &amp; Hotel Administration course
              offered at IHMs if they meet NCHMCT JEE cut off. Details of  NCHMCT JEE 2020  exam are
              mentioned in the table below:</p>
          </div>          
        </div>
        <div className="row t-mar">
          <div className="col-6 containlargbox2">
            <h6 className="text-center ">Details</h6>            
          </div>
          <div className="col-6 containlargbox2">
            <h6 className="text-center">Specifications</h6>            
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-6 mdcontainbox ">
            <p>Exam Name</p>
          </div>
          <div className="col-6 mdcontainbox border-r">
            <p>NCHMCT JEE</p>
          </div>
          <div className="col-6 mdcontainbox">
            <p>Conducting Body</p>
          </div>
          <div className="col-6 mdcontainbox border-r">
            <p>National Testing Agency (NTA)y</p>
          </div>
          <div className="col-6 mdcontainbox">
            <p>Exam Level</p>
          </div>
          <div className="col-6 mdcontainbox border-r">
            <p>National Level Examy</p>
          </div>
          <div className="col-6 mdcontainbox ">
            <p>Exam Frequency</p>
          </div>
          <div className="col-6 mdcontainbox border-r">
            <p>Once a year</p>
          </div>
          <div className="col-6 mdcontainbox">
            <p>Exam Mode</p>
          </div>
          <div className="col-6 mdcontainbox border-r">
            <p>Online (Remote proctored)</p>
          </div>
          <div className="col-6 mdcontainbox">
            <p>Exam Duration</p>
          </div>
          <div className="col-6 mdcontainbox border-r">
            <p>3 hours</p>
          </div>
          <div className="col-6 mdcontainbox">
            <p>Mode of Instruction</p>
          </div>
          <div className="col-6 mdcontainbox border-r">
            <p>English and Hindi</p>
          </div>
          <div className="col-6 mdcontainbox border-b">
            <p>Accepting Colleges</p>
          </div>
          <div className="col-6 mdcontainbox border-r border-b">
            <p>71 colleges</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 containlargbox centercontain">
            <h1>IHM Cutoffs</h1>
            <p>NTA on behalf of IHM releases category-wise opening and closing ranks for admission in each
              IHM. Candidates need to meet IHM cutoffs in order to be eligible for admission in hospitality
              courses offered at these hotel management colleges.
              Click here  for detailed information on NCHMCT JEE cutoffs.</p>
          </div>          
        </div>
        <div className="row">
          <div className="col-12 containlargbox">
            <h1>Seats in Hospitality Courses offered at IHMs</h1>
            <p>Admission in IHMs is offered based on an aspirant&#39;s performance in  NCHMCT JEE exam . Over the
              years, seats on offer for admissions at IHMs have increased. In 2020, more than 12,000 seats
              were available for admission in BSc in Hospitality &amp; Hotel Administration offered at IHMs.</p>
          </div>          
        </div>
        <div className="row">
          <div className="col-12 containlargbox">
            <h1>IHMs SEAT COUNT</h1> 
          </div>        
        </div>
        <div className="row t-mar">
         
          <div className="col-9 containlargbox2">
            <h6 className="text-center ">Name of the institute</h6>       
          </div>
          <div className="col-3 containlargbox2">
            <h6 className="text-center ">Number of seats</h6>       
          </div>
        </div>
        <div className="row t-mar">
          <div className="col-9 mdcontainbox">
            <p >Ambedkar Institute of Hotel Management Sector 42 D,
              Chandigarh – 160036</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >288</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management Pusa Campus
              New Delhi- 110012</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >305</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management Bhaijipura Patia, Kudasan – P.O.
              Gandhinagar- 382421</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >335</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management
              P.O. Alto Porvorim, Bardez, Goa-403 521</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >422</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management
              Village Bariar, G.T. Road Gurdaspur- 143521</p>       
          </div>
          <div className="col mdcontainbox border-r">
            <p >234</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management
              VIP Road, Upper Hengrabari, Barabari Guwahati-781 036</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >230</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management Airport Road, Maharajpur P.O.
              Gwalior- 474 005</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >300</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management
              Near Ramashish Chowk, Opp. State Circuit House, Hajipur (Vaishali)-
              844102</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >285</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management F Row, D D Colony, Vidyanagar
              Hyderabad- 500 007</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >285</p>       
          </div>
          <div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management
              Sikar Road, Near Bani Park Police Station Jaipur-302017</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >230</p>       
          </div><div className="col-9 mdcontainbox">
            <p >Institute of Hotel Management P-16, Taratala Road Kolkata- 700 088</p>       
          </div>
          <div className="col-3 mdcontainbox border-r">
            <p >380</p>       
          </div>
          <div className="col-9 mdcontainbox border-b">
            <p >Institute of Hotel Management
              Seed Farm, Sector-G, Aliganj Lucknow- 226024</p>       
          </div>
          <div className="col-3 mdcontainbox border-r border-b">
            <p >342</p>       
          </div>
        </div>
        
    </div>

   



  )
}

export default TableSections