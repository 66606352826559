import React from "react";

const Syllabus=()=> {
    return(
        <div className="syllabus-container">
            <div className="container-fluid">
                <h3>NCHMCT JEE 2022 Syllabus (Available) – Get Complete Syllabus Here</h3>
            <p>The National Testing Agency (NTA) provided NCHM JEE Syllabus 2022 for all the candidates so they understand from which topics queries are asked. In 2018, the National Council of hotel Management and catering Technology (NCHMCT) used to conduct the exam and that they used to provide the NCHM JEE syllabus 2022. With the help of the syllabus of NCHM JEE 2022, candidates get an idea on which topics is time consuming for them. </p>
            <p>Candidates also get to understand what are their weak and strong areas as they prepare for NCHMCT JEE entrance examination. By knowing NCHM JEE syllabus 2022 and also the exam pattern, candidates conjointly get to understand what are the type of queries that are asked and the way they’ll manage their time on the day of the exam.</p>
            
            <div style={{backgroundColor: "lightgrey"}}>
                <h3>NCHMCT JEE 2022 Syllabus:</h3>
                <p>The NCHMCT JEE online test exam appeared candidates can prepare the syllabus of NCHMCT JEE 2022 topics. Candidates who are getting additional marks within the NCHMCT JEE (National Council for hotel Management and catering Technology Joint Entrance Examination), they’ll study the syllabus of NCHMCT JEE topics and NCHMCT JEE previous year question papers. Candidates don’t skip any syllabus topics of NCHMCT JEE 2022 and should study all the topics of NCHMCT JEE. The NCHMCT JEE syllabus topics are given below.</p>
           <h3>Numerical Ability and Analytical Aptitude</h3>
           <p>English Language<br></br>
Reasoning & Logical Deduction<br></br>
General Knowledge & Current Affairs</p>
           <h3>Numerical Ability and Analytical Aptitude:</h3>
           <p>    <br></br> <span style={{paddingLeft:"6vh"}}> • Number Systems</span>
           <br></br> <span style={{paddingLeft:"6vh"}}>  • Square Roots</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Boats & Streams</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Clocks & Calendars</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Mixtures & Allegation</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Pipes & Cisterns</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Cube Roots</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Elementary Mathematics</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Arithmetic</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Fractions and Decimals</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • HCF & LCM</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Logarithms</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Problems on ages</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Races & Games</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Simple Interest & Compound Interest</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Percentage</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Profit and Loss</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Partnership</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>• Discounts</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Variation</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Chain Rule</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Ratio & Proportion Mensuration- Areas and Volumes</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Area and Perimeter of Plane Figure</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Volume & Surface Area of Solid</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Problems on Trains</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Time & Distance</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Time & Work</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Work & Wages</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Simplification</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Averages</span></p>
           
            <h3>English Language:</h3>

<p>  
       <br></br> <span style={{paddingLeft:"6vh"}}> • Antonyms</span>
    <br></br> <span style={{paddingLeft:"6vh"}}>  • Vocabulary</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Conclusion</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Comprehension</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Sentence Correction</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Passage Completion</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Sentence Completion</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Word Formation</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Error Correction</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Theme detection</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Fill in the Blanks</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Unseen Passages</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Idioms & Phrases</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Passage Correction</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Idioms and Phrases</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Tenses</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Articles</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Subject-Verb Agreement</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Sentence Rearrangement</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Grammar</span>
     <br></br> <span style={{paddingLeft:"6vh"}}> • Synonyms</span></p>

<h3>Reasoning & Logical Deduction:</h3>
           <p>
           <br></br> <span style={{paddingLeft:"6vh"}}> • Ordering & Sequencing</span>
  <br></br> <span style={{paddingLeft:"6vh"}}>   • Routes & Networks</span>
  <br></br> <span style={{paddingLeft:"6vh"}}>   • Analytical Reasoning</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Non-Verbal Series</span>
  <br></br> <span style={{paddingLeft:"6vh"}}>   • Complex Arrangements</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Analogies</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Classification</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Direction & Distances</span>
    <br></br> <span style={{paddingLeft:"6vh"}}> • Linear Arrangements</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Blood Relationships</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Symbols and Notations</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Alphabet Test</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Data Sufficiency Test</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Verbal Series</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Coding & Decoding</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Problems Based on Symmetry</span>
   <br></br> <span style={{paddingLeft:"6vh"}}>  • Problems Based on Visual Ability</span>
  <br></br> <span style={{paddingLeft:"6vh"}}>   • Data Interpretation Test</span>
  <br></br> <span style={{paddingLeft:"6vh"}}>   • Syllogism</span>
           </p>

<h3>General Knowledge & Current Affairs:</h3>

<p>    <br></br> <span style={{paddingLeft:"6vh"}}>    • General Politics</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Economic Scene</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Scientific Research</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Science – Inventions & Discoveries</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • India and its neighboring countries</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • National Current Affairs</span>
      <br></br> <span style={{paddingLeft:"6vh"}}>  • International Current Affairs</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Countries & Capitals</span>
      <br></br> <span style={{paddingLeft:"6vh"}}>  • Knowledge of Current Events</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Current events</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Important days</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Sports</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • History</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Culture</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Abbreviations</span>
     <br></br> <span style={{paddingLeft:"6vh"}}>   • Indian Constitution</span></p>


            </div>  
            </div>
            <a href="/nchmct-study-material"><p>You May also want to know about NCHMCT Study materials</p></a>
<a href="/nchmct-exam-centres"><p>You May also want to know about NCHMCT Exam Centres</p></a>
<a href="/nchmct-jee-exam"><p>You May also want to know about NCHMCT JEE</p></a>
        </div>
    )
}
export default Syllabus;