import React from "react";
import LOGO from "../assets/images/LogoFreeCrop.png";
import { Link } from "@reach/router";

function Footer() {
  return (
    <div>
      <footer className="mt-5">
        <h4>Courses offered by IHMs in India Academics</h4>
        <p className="mt-3">
          Academics for  BSc Hospitality &amp; Hotel Administration  and ten
          other courses of study offered at IHMs are run and regulated by
          National Council for Hotel Management &amp; Catering Technology
          (NCHMCT) which is an autonomous body that falls under the ambit of
          Ministry of Tourism, Government of India.
        </p>

        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
        >
          <p className="discailmer mt-2">
          Disclaimer : The website is made available for use to help students get details, discover & research on IHM's colleges, courses and exams of their interest and for allied services related thereto. This is just an information mouth piece.
          </p>
        </div>
        <div className="text-center p-3" >
          {/* <Link to="/">
            <img src={LOGO} alt="logo" />
          </Link> */}
          <a href="/privacy-policy" 
          // style={{textDecoration:" none"}}
          ><p style={{textAlign: "center"}}>Privacy & Policies</p></a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
