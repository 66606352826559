import React from "react";

const Privacy=()=> {
    return(
        <div className="syllabus-container">
            <h3 style={{fontWeight: "bold"}}>Policy</h3>
            <h3 style={{fontFamily: "italic"}}>Privacy Policy</h3>
            <p>As a general rule, this website does not collect Personal Information about you when you visit the site. You can generally visit the site without revealing Personal Information unless you choose to provide such information.</p>
       <h3 style={{fontFamily: "italic"}}>Site Visit Data:</h3>
       <p>This website records your visit and logs the following information for statistical purposes your server's address; the name of the top-level domain from which you access the Internet (for example, .gov, .com, .in, etc.); the type of browser you use; the date and time you access the site; the pages you have accessed and the documents downloaded and the previous Internet address from which you linked directly to the site. We will not identify users or their browsing activities, except when a law enforcement agency may exercise a warrant to inspect the service provider's logs.</p>
        <h3 style={{fontWeight: "bold"}}>Cookies</h3>
        <p>A cookie is a piece of software code that an internet website sends to your browser when you access information at that site. This site does not use cookies.</p>
        <h3 style={{fontFamily: "italic"}}>
        Email Management:
        </h3>
        <p>Your email address will only be recorded if you choose to send a message. It will only be used for the purpose for which you have provided it and will not be added to a mailing list. Your email address will not be used for any other purpose, and will not be disclosed, without your consent.</p>
        <h3 style={{fontFamily: "italic"}}>
        Collection of Personal Information:
        </h3>
        <p>If you are asked for any other Personal Information you will be informed how it will be used if you choose to give it. If at any time you believe the principles referred to in this privacy statement have not been followed, or have any other comments on these principles, please notify the webmaster through the contact us page.</p>
        <h3 style={{fontFamily: "italic"}}>
        Note:
        </h3>
        <p>The use of the term "Personal Information" in this privacy statement refers to any information from which your identity is apparent or can be reasonably ascertained.</p>
       <h3 style={{fontWeight: "bold"}}>Copyright Policy</h3>
       <p>The contents of this website may not be reproduced partially or fully, without due permission from the ihminfo.co.in. If referred to as a part of another publication, the source must be appropriately acknowledged. The contents of this website cannot be used in any misleading or objectionable context.</p>
       <h3 style={{fontWeight: "bold"}}>Hyperlink Policy</h3>

       <h3 style={{fontFamily: "italic"}}>Links To External Websites/Portals</h3>
       <p>At many places on this website, you shall find links to other websites/portals. This link has been placed for your convenience.  ihminfo.co.in is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them. The mere presence of the link or its listing on this website should not be assumed as an endorsement of any kind. We cannot guarantee that these links will work all the time and we have no control over the availability of linked pages.</p>
        </div>
    )
}
export default Privacy