import React from "react";

const NchmctJEE =()=>{
return(
    <div className="nchmctjee-container">
        <h1 className="text-center">NCHMCT JEE</h1>
        <div className="container">
        <div className="nchmctJee">
            <h3>
            What is NCHMCT JEE Exam?
            </h3>
            <p>National Council for Hotel Management and Catering Technology (NCHMCT) was established in 1982 as an autonomous body for the growth and development of hospitality management education in the country.  NCHMCT regulates academics in the field of hospitality education & training that is imparted at the 21 central government affiliated Institutes of Hotel Management, 27 state government affiliated IHMs offering degree-level programs; 3 state government-sponsored IHMs offering diploma/craft certificate level programs, 1 PSU Institute of Hotel Management, 29 private institutes, and 9 Food Craft Institutes that function in different parts of the country. Through the Council, these institutes offer a total of 11 different professional programs.  For admission to 3 years 6 semesters B.Sc Hospitality & Hotel Administration program in more than 63 IHMs that include govt as well as private under the academic umbrella of NCHMCT, candidates need to qualify in the NCHMCT JEE (Joint Entrance Exam). </p>
        <h3>Who Will Conduct NCHMCT JEE? </h3>
        <p>NCHMCT JEE also known as NCHM JEE is a national-level entrance exam conducted by the National Testing Agency (NTA) for admission to undergraduate courses. As per the government of India policy, from 2019 onwards, NCHM JEE will be conducted by the Central Government Agency created by the HRD Ministry, ''National Testing Agency.</p>
        <h3>NCHMCT JEE Eligibility</h3>
        <p>Candidates need to have all the eligibility requirements set by the respective body. Candidates should pass the 10+2 or its equivalent with English as a subject of study from a recognised central or state board. Candidates who're appearing or waiting for the final year result are also eligible to apply. The upper age limit of a candidate is 22 years. However, age relaxation is provided to reserved categories.</p>
       <h3>How To Apply For NCHMCT JEE</h3>
       <p>Candidates have to apply online to take the entrance exam. One should start applying to the NCHMCT JEE by filling in the basic details such as name and date of birth. Applicants are advised to scan the photograph and signature, prior to filling out the application form. </p>
        <h3>NCHMCT JEE Date, Fee Details & Application Fee / Procedure: REVISED EXTENSION OF THE LAST DATE FOR SUBMISSION OF ONLINE APPLICATIONS FOR THE NATIONAL COUNCIL FOR HOTEL MANAGEMENT JOINT ENTRANCE EXAMINATION (NCHM JEE) – 2022</h3>
       
        <div className="container-fluid">
            <table style={{width: "100%", textAlign: "center"}}>
                
                <tbody>
                    <tr>
                        <td>Online Registration & Submission of Application Form Extended </td>
                        <td>04 February, 2022 to 16th May, 2022 - Upto 5:00 PM</td>
                    </tr>
                    <tr>
                        <td>Last date for successful transaction of Examination Fee (through Credit / Debit / Netbanking, Paytm Payment Modes)
</td>
<td>16th May, 2022 -  Upto 11:50 PM
</td>
                    </tr>
                    <tr>
                        <td>
                        Correction in the Particulars of Application Form Online only
                        </td>
                        <td>
                        18 to 20 May, 2022
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Downloading of Admit Card by the Candidatefrom NTA Website
                        </td>
                        <td>
                        To be announced
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Mode of Exam
                        </td>
                        <td>
                        Computer Based Test (CBT)
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Pattern of Exam
                        </td>
                        <td>
                        Exam Comprises Multiple Choice Questions
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Duration of Examination

                        </td>
                        <td>
                        180 Minutes (3 Hours) 

                        </td>
                    </tr>
                    <tr>
                        <td>
                        Time of Examination

                        </td>
                        <td>
                        10:00 AM to 1:00 PM 
                            </td>
                    </tr>
                    <tr>
                        <td>
                        Exam Centre
                        </td>
                        <td>
                        As indicated on the Admit Card 
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Date of Exam
                        </td>
                        <td>
                        18.06.2022 (Saturday) 
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Declaration of the Result
                        </td>
                        <td>
                        To be announced
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
<h3>Fee Payable for NCHM JEE - 2021 (Through Canara Bank / PAYTM Payment Gateway through the Payment modes: Credit / Debit Card / Net-Banking / UPI / Wallet) </h3>       
       <div className="container-fluid">
           <table style={{width: "100%", textAlign: "center"}}>
               
               <tbody>
                   <tr>
                       <td>General (UR) / OBC - (NCL) as per Central List </td>
                       <td>Rs. 1000/-</td>
                   </tr>
                   <tr>
                       <td>
                       Gen-EWS
</td>
<td>Rs. 700/-
</td>
                   </tr>
                   <tr>
                       <td>
                       Schedule Caste (SC) Scheduled Tribes (ST) / PwD 
                       </td>
                       <td>
                       Rs. 450/-
                       </td>
                   </tr>
                   <tr>
                       <td>
                       Transgender
                       </td>
                       <td>
                       Rs. 450/-
                       </td>
                   </tr>
                
                 <tr>
                <td colSpan={12} style={{fontWeight: "bold", color: "black", fontSize: "large"}}>Applicable service / processing charges & GST over and above the Examination Fee, are to be paid by the candidate to the concerned Bank / Payment Gateway Integrator.
                </td> </tr>
               </tbody>
           </table>
       </div>
       <h3>
       NCHMCT JEE Exam Pattern
       </h3>
       <p>The organising body has decided to conduct the NCHMCT JEE in an online mode. The written exam will be held in 33 or more cities across the country. However, the exam scheme will remain same as the previous years on the following components. The duration of the exam is three hours. </p>
        <div className="container-fluid">
            <table style={{width: "100%", textAlign: "center"}}> 
                <tbody>
                    <tr style={{fontWeight: "bold", }}>
                        <td style={{color: "black"}}>
S. No
                        </td>
                        <td style={{color: "black"}}>
                            Subject
                            </td>
                            <td style={{color: "black"}}>
                            No. of Questions
                            </td>
                    </tr>
                    <tr>
                        <td>
                            1
                        </td>
                        <td>
                        Numerical Ability and Analytical Aptitude 

                        </td>
                        <td>
                            30
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2
                        </td>
                        <td>Reasoning and Logical Deduction 
</td><td>30</td>
                    </tr>
                    <tr>
                        <td>3</td><td>General Knowledge & Current Affairs 
</td><td>30</td>
                    </tr>
                    <tr>
                        <td>4</td><td>English Language  
</td><td>60</td>
                    </tr>
                    <tr>
                        <td>5</td><td>Aptitude for Service Sector 

</td><td>50</td>
                    </tr>
                    <tr>
                        <td></td><td style={{textAlign: "right"}}>Total
</td><td style={{fontWeight: "bold"}}>200</td>
                    </tr>
                </tbody>
             </table>
        </div>
        <h3>NCHMCT JEE Admit Card</h3>
        <p>Candidates who have submitted the application form can download the admit card from the official website. Aspirants have to make a note that the admit cards will not be sent through the post. In order to download the admit card, one should enter the details such as application number, date of birth and email ID. Candidates have to deposit one copy of the self-attested NCHMCT JEE admit card with the invigilator. Another copy of the NCHMCT JEE admit card must be presented during the examination. </p>
      <h3>NCHMCT JEE Syllabus</h3>
      <p>The organising body will not publish any detailed syllabus for the NCHMCT JEE. However, we have prepared a list of topics after assessing the previous year's question papers. To know more about the NCHMCT JEE syllabus</p>
       <h3>
       NCHMCT JEE Cut-off Score
       </h3>
       <p>The selection of the candidate will be purely based the merit. The marks scored in the online exam will be considered for qualifying. One should reach the cut-off score set by the organizing body to get admission.</p>
      <h2>NCHMCT JEE Eligibility Criteria </h2>
      <h3>Educational Qualification:</h3>
      <p>Candidates should have completed plus two level examination in the 10+2 pattern of Senior Secondary education of any recognized Central/State Board, such as Central Board of Secondary Education and Council for Indian School Certificate Examination or State Boards of Secondary Education.
    <br></br><br></br> <span style={{paddingLeft:"6vh"}}>• Intermediate or two-year Pre-University Examination conducted by a recognized institution
    </span> <br></br> <span style={{paddingLeft:"6vh"}}>• General Certificate Education (GCE) Examination (London/Cambridge/Sri Lanka) at the Advanced (A) level
  </span> <br></br> <span style={{paddingLeft:"6vh"}}> • High School Certificate Examination of the Cambridge University </span>
  <br></br> <span style={{paddingLeft:"6vh"}}> • Any Public School/Board/University Examination in India or in a foreign country recognized by the Association of Indian Universities as equivalent to 10+2 pattern of Senior Secondary education
   </span> <br></br> <span style={{paddingLeft:"6vh"}}> • HSC Vocational Examination approved by NCVT and SCVT of concerned state </span>
   <br></br> <span style={{paddingLeft:"6vh"}}> • Senior Secondary School Examination conducted by National Institute of Open School (NIOS) as well as State Board of Open Schooling with a minimum of five subjects including English as one of the Subjects
   </span> </p>
   <h3>
   Age Limit
   </h3>
   <p>   <br></br> <span style={{paddingLeft:"6vh"}}> • Candidates belonging to General and OBC categories, the upper age limit is 22 years as on July 1, 2022. 
   </span><br></br> <span style={{paddingLeft:"6vh"}}> • While for candidates belonging to Scheduled Caste and Scheduled Tribe the upper age limit is 28 years as on July 1, 2022.
   </span> <br></br> <span style={{paddingLeft:"6vh"}}>• For Physically Challenged candidates shall be reckoned as per the category he/she belongs to i.e. General/SC/ST/OBC. </span></p>
        </div>
        </div>
        <a href="/nchmct-exam-centres"><p>You May also want to know about NCHMCT Exam Centres</p></a>
<a href="/nchmct-syllabus"><p>You May also want to know about NCHMCT Syllabus</p></a>
<a href="/nchmct-study-material"><p>You May also want to know about NCHMCT Study materials</p></a>
    </div>
)
}
export default NchmctJEE;