import { navigate } from "@reach/router";
import React, { useEffect } from "react";

function Thankyou() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="container thankyou-container">
        <div className="row ma-s">
          <div className="col-md-12">
            <div className="thankyou">
              <h1 className="text-center">
                Thank you for your enquiry. We shall revert at the earliest.For
                further details, you may please visit IHM INFO
              </h1>
            </div>
          </div>
        </div>
        <div className="row ma-s">
          <div className="col-md-12 thankyou-disclaimer">
            <div className="thankyou-disclaimer-c">
              <h2 className="text-center">
                {" "}
                This site is just for awareness of IHM admission procedure only.
              </h2>
            </div>
            <div className="thankyou-social-link">
              <a target="_blank" href=" https://www.facebook.com/ihm.info">
                <i class="bi bi-facebook"></i>
              </a>
              <a target="_blank" href=" https://www.instagram.com/ihm.info/">
                <i class="bi bi-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Thankyou;
