import React, { Component, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import banner1 from "../assets/images/topslider/hmimg1.png";
import banner2 from "../assets/images/topslider/hmimg2.png";
import banner3 from "../assets/images/topslider/hmimg3.png";
import banner4 from "../assets/images/topslider/hmimg4.png";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import { navigate } from "@reach/router";
import Toast from "sweetalert2";

function FormContent() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [location, setLocation] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrMsg, setNumberErrMsg] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [locationErrMsg, setLocationErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const valid = () => {
    if (name.length < 2) {
      setNameError(true);
      setNameErrMsg("This is a mandatory field, please fill");
      return false;
    }
    if (email.length < 2) {
      setEmailError(true);
      setEmailErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (number.length < 2) {
      setNumberError(true);
      setNumberErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (location.length < 2) {
      setLocationError(true);
      setLocationErrMsg("This is a mandatory field, please fill");
      return false;
    } else {
      return true;
    }
  };

  const Testing = (successText) => {
    const Toast = Swal.mixin({
      position: "center",
      showConfirmButton: false,
      timer: 4000,

      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      background: "white",
      iconColor: "green",
      color: "green",
      title: successText,
    });
  };

  const FormSubmitted = () => {
    setNumberError(false);
    setNameError(false);
    setEmailError(false);
    setLocationError(false);
    console.log("name", name.length);
    if (valid()) {
      setLoading(true);
      const payload = {
        name: name,
        email: email,
        phone: number,
        location: location,
      };
      axios
        .post("https://ihminfo.co.in/backend/MasterApi/saveInfo", payload)
        .then((response) => {
          setLoading(false);
          setName("");
          setEmail("");
          setNumber("");
          setLocation("");
          console.log(response.data.result);
          if (response.data.result === 1) {
            setSuccessMsg(response.data.message);
            navigate("/thank_you");
            // Testing(response.data.message);
            setTimeout(() => {
              // setSuccessMsg(false);
            }, 3000);
          }
        });
    } else {
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8  explainboxv2">
          <div className="boxitem containboxv2">
            <h3>List of Central Institutes of Hotel Management</h3>
            <ul>
              <li>IHM Pusa , Est 1962 – India Today Rank 1</li>
              <li>IHM Mumbai , Est 1954– India Today Rank 3</li>
              <li>IHM Hyderabad , Est 1972 – India Today Rank 4</li>
              <li>IHM Chennai , Est 1963 – India Today Rank 5</li>
              <li>IHM Lucknow , Est 1969 – India Today Rank 6</li>
              <li>IHM Chandigarh , Est 1990 – India Today Rank 8</li>
              <li>IHM Kolkata , Est 1963 – India Today Rank 10</li>
              <li>IHM Bhopal , Est 1978 – India Today Rank 11</li>
              <li>IHM Kovalam , Est 1990 – India Today Rank 13</li>
              <li>IHM Shimla , Est 1984 – India Today Rank 14</li>
            </ul>
            <h3>List of State Government Institutes of Hotel Management</h3>
            <ul>
              <li>Chandigarh Institute of Hotel Management , Est 1973</li>
              <li>Delhi Institute of Hotel Management , Est 1983</li>
              <li>IHM Dehradun , Est 2006 – India Today Rank 18</li>
              <li>IHM Faridabad , Est 1989</li>
              <li>SIHM Gangtok , Est 1990</li>
              <li>SIHM Jodhpur , Est 1996</li>
              <li>SIHM Kozhikode , Est 1981</li>
              <li>IHM Kurukshetra , Est 2008</li>
              <li>IHM Bathinda , Est 2009</li>
              <li>SIHM Hamirpur , Est 2009</li>
            </ul>
            <section></section>
          </div>
        </div>
        <div className="col-md-4">
          <section className="formbox " style={{ zIndex: 20 }}>
            <h3> TO GET MORE INFO PLEASE CONTACT</h3>
            <form>
              <input
                className="form-control"
                id="disabledInput"
                type="text"
                value={name}
                placeholder="Name*"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {nameError ? <span className="fromerror">{nameErrMsg}</span> : ""}
              <input
                type="email"
                className="form-control"
                placeholder="Email id*"
                id="exampleInputEmail1"
                value={email}
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {emailError ? (
                <span className="fromerror">{emailErrMsg}</span>
              ) : (
                ""
              )}
              <input
                className="form-control"
                id="disabledInput"
                type="number"
                maxLength="10"
                value={number}
                placeholder="Phone No.*"
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
              {numberError ? (
                <span className="fromerror">{numberErrMsg}</span>
              ) : (
                ""
              )}
              <input
                className="form-control"
                id="disabledInput"
                type="text"
                placeholder="Location*"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
              {locationError ? (
                <span className="fromerror">{locationErrMsg}</span>
              ) : (
                ""
              )}

              <button
                type="button"
                // data-bs-toggle="modal"
                // data-bs-target="#exampleModal"
                onClick={FormSubmitted}
              >
                {loading ? (
                  <ClipLoader color="deepskyblue" />
                ) : (
                  <span> SUBMIT</span>
                )}
              </button>
            </form>
          </section>
          <section className="videosec mt-5">
            <div
              id="carouselExampleSlidesOnly"
              class="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner cam-box">
                <div className="carousel-item active">
                  <img className="sliderfadeimg" src={banner1} alt="..." />
                </div>
                <div className="carousel-item ">
                  <img className="sliderfadeimg" src={banner2} alt="..." />
                </div>
                <div className="carousel-item">
                  <img className="sliderfadeimg" src={banner3} alt="..." />
                </div>
                <div className="carousel-item">
                  <img className="sliderfadeimg" src={banner4} alt="..." />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default FormContent;
