import React,{useEffect} from "react";
import Footer from "./Footer";

function NCHMExamRules() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])
  return (
    <>
      <div className="container NCHMExamRules-container">
        <div className="row ma-s lunch-box">
          <div className="col-md-12">
            <h1 className="text-center">
              NATIONAL COUNCIL FOR  HOTEL MANAGEMENT  AND CATERING TECHNOLOGY
            </h1>
            <img
              className="NCHMExamRules-bannerlogo-img"
              src=""
              alt="img"
            ></img>
            <h2 className="text-center">Examination Rules 2017</h2>
            <p className="text-center">(With effect from July 2017)</p>
          </div>
        </div>
        <div className="row ma-s ">
          <div className="col-md-12">
            <div className="NCHMExamRules-contain2 ">
              <h1 className="text-center">
                NATIONAL COUNCIL FOR HOTEL MANAGEMENT <br></br>AND CATERING
                TECHNOLOGY
              </h1>
              <p className="text-center">A-34, SECTOR-62 NOIDA – 201 309</p>
            </div>
            <div className="NCHMExamRules">
              <h2 className="text-center">EXAMINATION RULES</h2>
              <h4>1.0 INTRODUCTION</h4>
              <p>
                1.1 These rules shall hereafter be called Examination Rules for
                National Council for Hotel Management & Catering Technology.
              </p>
              <p>
                1.2 These rules shall supersede all existing rules for such
                examinations and shall come into force with effect from 2017-18
                Academic Year beginning July 2017..
              </p>
              <p>
                1.3 These rules will be subject to alterations and amendments by
                the Council only with due notice of one clear academic session.
              </p>
              <h4>2.0 DEFINITIONS</h4>
              <p className="p2">In these rules unless otherwise repugnant;</p>
              <p>
                2.1 <strong>Academic year</strong> is the period of time in a
                year scheduled for teaching. Each year will be comprised of two
                semesters/ terms..
              </p>
              <p>
                2.2 <strong>Chairman</strong> shall mean the Chairman of Board
                of Governors of the National Council for Hotel Management &
                Catering Technology, New Delhi.
              </p>
              <p>
                2.3 <strong>Chief Executive Officer (CEO)</strong> shall mean
                the Chief Executive Officer of the National Council for Hotel
                Management & Catering Technology.
              </p>
              <p>
                2.4 <strong>Controller of Examinations</strong> shall mean the
                Controller of Examinations of the National Council for Hotel
                Management & will be responsible for the conduct of
                examinationsand for making arrangement for declaration of
                results of the examinations held by the National Council for
                Hotel Management and Catering Technology
              </p>
              <p>
                2.5 <strong>Council</strong> shall mean the National Council for
                Hotel Management and Catering Technology.
              </p>
              <p>
                2.6 <strong>Examination</strong> shall mean any examination
                conducted by the National Council for Hotel Management and
                Catering Technology.
              </p>
              <p>
                2.7 <strong>Examination Committee</strong> shall mean the
                Committee of the National Council for Hotel Management entrusted
                with the task of finalising examination matters.
              </p>
              <p>
                2.8 <strong>Institute</strong> shall mean Institute affiliated
                to National Council for Hotel Management and Catering
                Technology.
              </p>
              <p>
                2.9 <strong>Principal</strong> shall mean the Head of the
                affiliated Institute.
              </p>
              <p>
                2.10 <strong>Secretary</strong> shall mean the Secretary of the
                National Council for Hotel Management and Catering Technology,
                New Delhi.
              </p>
              <p>
                2.11 <strong>Session</strong> is the period of time of a
                semester or term scheduled for teaching.
              </p>
              <p>
                2.12 <strong>Superintendent</strong> shall mean Superintendent
                of the Examination Centre.
              </p>
              <p>
                2.13 <strong>University</strong> shall mean the degree awarding
                university for degree courses.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>3.0 EXAMINATION COMMITTEE, EXAMINATION SCHEDULE</h4>
              <p className="p2">3.1 Examination Committee</p>
              <p className="sub-child">
                3.1.1 The Examination Committee shall consist of following
                members:
                <ul className="list-alpha">
                  <li>
                    The Controller of Examinations of NCHMCT shall be the
                    Ex-Officio Convenor of Examination Committee. If there is no
                    such person, or the post is lying vacant, the officer who is
                    holding the charge for the time being shall be the Convenor
                    of the Examination Committee;
                  </li>
                  <li>
                    Director (Studies), National Council for Hotel Management &
                    Catering Technology or his/ her nominee;
                  </li>
                  <li>
                    The Member Secretary of the Board of Governors of NCHMCT or
                    his/ hernominee; and
                  </li>
                  <li>
                    Four Principals of the affiliated institutes to be nominated
                    by the Board of Governors.
                  </li>
                  <li>
                    The Convenor may co-opt an expert as member for a meeting.
                  </li>
                </ul>
              </p>
              <p className="sub-child">
                3.1.2 The tenure of nominated members shall be three academic
                years. In case of delay in the nomination, the members nominated
                earlier shall continue to function. The Board of Governors may
                reconstitute the committee before the completion of tenure, if
                it so desires.
              </p>
              <p className="sub-child">
                3.1.3 Five members present at any meeting shall constitute a
                quorum.
              </p>
              <p className="sub-child">
                3.1.4 Convenor shall maintain record of all decisions of the
                Examination Committee in a book form.
              </p>
              <p className="sub-child">
                3.1.5 TA/DA of co-opted and nominated members shall be borne by
                the Council.
              </p>
            </div>
            <div className="NCHMExamRules">
              <p className="p2">3.2 Examination:</p>
              <p className="sub-child">
                3.2.1 The National Council for Hotel Management and Catering
                Technology shall conduct the end term examination of each course
                affiliated to it whether full time or short time or as decided
                by the Council from time to time.
              </p>
              <p className="sub-child">
                3.2.2 The examination shall be held as per event calendar
                notified each year.
              </p>
              <p>
                3.3 The medium of examination shall be English unless specified.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>4.0 ELIGIBILITY FOR ADMISSION TO THE EXAMINATION</h4>
              <p className="sub-child">
                4.1 In order to be eligible to take the Council’s examinations
                (any semester/ term or course), a candidate must have;
              </p>
              <p className="sub-child">
                4.1.1 been on the roll of an affiliated institution for one full
                academic session (period of study) in the course for which
                he/she wants to take the examination;
              </p>
              <p className="sub-child">
                4.1.2 submitted his/her application to the Controller of
                Examinations through the head of the institution last attended
                or any other authority nominated by the National Council for
                Hotel Management and Catering Technology for this purpose;
              </p>
              <p className="sub-child">
                4.1.3 produced the certificate signed by the forwarding
                authority of having attended not less than 75% of the aggregate
                of all lectures, practical and tutorial classes held during the
                session (effective teaching hours/ contact hours). Attendance in
                lectures, practical and tutorials shall be counted from the date
                of commencement of session as per Council's calendar.
                <br></br>
                <br></br>
                <strong>Note:</strong> A student is expected to attend 100%
                classes i.e theory, practical & Tutorial. The above relaxation
                is provided for absence due to unforeseen reason.
                <ul className="list-italic">
                  <li>
                    Every period of lecture, tutorial, practical, project and
                    workshop work as applicable shall be counted as one unit for
                    the purpose of counting of attendance e.g. if a class covers
                    four periods at a time, four units of attendance will be
                    counted.
                  </li>
                  <li>
                    Each candidate should have carried out laboratory, practical
                    assignment, project work etc. prescribed in the course taken
                    by him/ her to the satisfaction of the Head of the
                    Institution and certified by the Head of that Institution as
                    fit for appearing at the examination as regards progress,
                    conduct and character provided that such candidates have not
                    been debarred from appearing for any examination held by any
                    Government or constituted statutory examination authority in
                    India at the time when examinations are held by the National
                    Council for Hotel Management & Catering Technology.
                  </li>
                  <li>
                    A student engaged in extra-curricular and co-curricular
                    activities, with the permission of the Principal (Food
                    exhibitions, Chef Competition, Education tour, etc.) shall
                    be treated as present for the purpose of counting attendance
                    in accordance with the timetable, for which record will be
                    maintained by the institute.
                  </li>
                  <li>
                    When on account of bonafide illness, (supported by a Medical
                    Certificate from a Registered Medical Practitioner produced
                    by the candidate at the time of illness or immediately
                    thereafter) or for any other reason deemed sufficient by the
                    Head of the Institution (e.g. death in family), the total
                    attendance of a candidate falls short up to a maximum of 10
                    percent of the total working days, the Head of the
                    Institution shall be competent to condone such deficiency in
                    attendance and permit such a candidate to appear for the
                    examination, if otherwise eligible.
                  </li>
                </ul>
                <br></br>
                <strong>Note:</strong> Industrial Training shall not form part
                of above and will be treatedseparately.
              </p>
              <p className="sub-child">
                4.1.4 A candidate who is disallowed from appearing for an
                examination for not fulfilling the conditions at 4.1.3 above
                shall be required to fulfil all the conditions afresh, before
                being entitled to appear for the examination.
              </p>
              <p className="sub-child">
                4.1.5 Those candidates who, because of being ineligible to
                appear in any of the National Council Examinations, are detained
                in the same semester/ term whether on account of shortage in
                attendance or for any other reasons will be required to repeat
                that semester/ term as a regular student in the following year
                and may, if otherwise eligible and if so permitted, appear in
                the next semester/ term examination.
              </p>
              <p className="sub-child">
                4.1.6 Industrial Training (III or IV Semester of B.Sc in
                Hospitality & Hotel Administration):
                <br></br>
                Industrial Training will require an input of 102 days (17 weeks
                X 6 days per week = 102 days). To qualify for examination a
                student must login at least 87 days of Industrial Training.
                Student can avail 15 days leave with prior permission of hotel
                authorities. A student, who fails to attend 87 days of
                Industrial Training will not be allowed to appear for
                examination and treated as fail in the subject. Such a student
                will be allowed to complete the Industrial Training shortfall
                during vacation and appear in the subsequent examination, if
                otherwise eligible.
              </p>
              <p>
                4.2 A candidate, who fulfils the conditions at para 4.1 above
                but does not appear for the examination, may at his/ her option
                appear for the subsequent examination as an external student. In
                such cases the internal assessment put in shall be preserved by
                the Head of the Institution and presented at the subsequent
                examination.
              </p>
              <p>
                4.3 Attendance of the students, provisionally promoted to the
                next higher class, shall be counted from the date of
                commencement of session.
              </p>
              <p>
                4.4 A candidate having passed an examination shall not be
                entitled to re-appear for the same examination.
              </p>
              <p>
                4.5.1 All applications for admission to the examination, shall
                be accompanied by the necessary fee prescribed by the National
                Council for Hotel Management and forwarded through the Head of
                the Institution on or before the last date notified by the
                National Council every year. An additional late fee as
                prescribed in Rule 5 shall be charged for each late application
                received in the National Council’s office not later than 15 days
                after the notified last date and up to 30 days before the
                commencement of examination. The Controller of Examination,
                National Council for Hotel Management & Catering Technology
                shall, however, be competent to accept such late applications
                without any late fees even after the notified date in case he is
                satisfied that the application was in fact submitted by the
                candidate to the Institution in time but could not reach the
                National Council’s office before the notified date on account of
                failure of authorities in the Institution to do so.
              </p>
              <p>
                4.5.2 The National Council will accept no application, which has
                not been so submitted or is not complete in every respect. The
                Head of the Institution shall be competent to refuse to forward
                to the National Council any such application, which is not
                complete in every respect.
              </p>
              <p>
                4.5.3 Notwithstanding the forwarding of applications for
                admission to examination and payment of examination fee through
                the Head of Institution and allocation of Examination Roll
                Numbers and/ or release of Admit Card by the National Council,
                the Head of the Institution shall be competent to withdraw such
                applications in respect of those candidates who do not fulfil
                any of the conditions laid down under para 4.1 above, at any
                time before the commencement of the examination and to disallow
                those candidates from appearing for the examination.
              </p>
              <p>
                4.6 Students who wish to continue their studies, after having
                been out of the institute for not more than two years due to any
                reason, except those who for adopting unfair means or found
                guilty for gross indiscipline etc. and having been debarred by
                the competent authority from appearing in the examination
                conducted by the National Council, could do so within one week
                from the commencement of the session. The period of absence
                shall be counted from session to session and not from the date
                candidate leaves the institution. Attendance for such candidates
                shall be counted from the date of commencement of session.
              </p>
              <p className="p2">4.7 DURATION TO CLEAR A COURSE:</p>
              <p>
                Period to clear/ pass all papers/ subjects of a course shall be
                as under:
              </p>

              <table>
                <tr>
                  <th>COURSE</th>
                  <th>MAXIMUM DURATION</th>
                </tr>
                <tr>
                  <td>
                    Two years Masters Degree in Hospitality Administration
                  </td>
                  <td>Four Academic years</td>
                </tr>
                <tr>
                  <td>
                    One and Half year Post Graduate Diploma in Dietetics and
                    Hospital Food Service
                  </td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>
                    One and Half year Post Graduate Diploma in Accommodation
                    Operations and Management
                  </td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>
                    Three years Bachelors Degree in Hospitality & Hotel
                    Administration
                  </td>
                  <td>Six Academic Years</td>
                </tr>
                <tr>
                  <td>One and Half year Diploma in Food Production</td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>One and Half year Diploma in Bakery & Confectionery</td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>One and Half year Diploma in Food & Beverage Service</td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>One and Half year Diploma in Housekeeping</td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>One and Half year Diploma in Front Office Operations</td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>
                    One and Half year Craftsmanship Certificate Course in Food
                    Production and Patisserie
                  </td>
                  <td>Three Academic Years</td>
                </tr>
                <tr>
                  <td>
                    Six months Craftsmanship Certificate Course in Food and
                    Beverage Service
                  </td>
                  <td>Three Academic sessions/ terms</td>
                </tr>
              </table>

              <p>
                Failure to clear the subject(s) in the last year/ session will
                in effect result in the candidate being OUT OF SYSTEM. Such
                candidates desirous to continue the course will have to seek
                fresh admission in the first semester/ year of the course as per
                the manner prescribed therefor by the National Council.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>5.0 EXAMINATION FEE</h4>
              <p>
                5.1 Every candidate shall submit his/her application to the Head
                of the Institution by the last date as notified by the
                Controller of Examinations and Head of Institute shall further
                forward the applications to the Controller of Examinations so as
                to reach within 8 days of the last date so notified subject to
                the conditions laid down in Rule 4. Applications received late
                from the Head of the Institution are liable to be rejected.
              </p>
              <p>
                5.2 Examination fee/ rates shall be as notified from time to
                time.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>6.0 NON-REFUND OF EXAMINATION FEES</h4>
              <p>
                6.1 Fees once paid shall not be refunded except in such cases as
                mentioned in Rule 6.2 below.
              </p>
              <p>
                6.2 The examination fee paid by such candidates who are not
                permitted to appear in the examination shall be refunded on
                receipt of an application by the Controller of Examinations in
                writing to that effect from the candidate concerned within 3
                months of the commencement of the Examination. No application
                will be entertained if received after the date mentioned above.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>7.0 CONDUCT OF EXAMINATION</h4>
              <p>
                7.1 The Controller of examinations shall decide and notify the
                centres where examinations are to be held. For each such centre,
                the Controller will appoint the Superintendent of Examination.
              </p>
              <p>
                7.2 The Head of Institution running affiliated courses shall be
                required to inform the Controller of Examinations, National
                Council for Hotel Management & Catering Technology the number of
                candidates to take such examination by the date(s) so notified
                by the Controller of Examinations.
              </p>
              <p>
                7.3 The Superintendent of Examination shall appoint all other
                Supervisory and ancillary staff required as approved by the
                Controller of Examinations.
              </p>
              <p>
                7.4 The number of staff to be appointed for the centres will
                depend on the number of halls/rooms, labs. Workshops etc. in
                which the examination is being conducted. One invigilator shall
                be allowed for every 20 candidates or part thereof with a
                minimum of two invigilators per room or hall, in case of written
                examinations.
              </p>
              <p>
                7.5 An additional invigilator will be provided to help the
                Superintendent of the centre.
              </p>
              <p>
                7.6 In the conduct of practical examination, the Instructor who
                has been conducting practical classes shall normally be
                appointed as Internal Examiner.
              </p>
              <p>
                7.7 Examination Superintendent will ensure proper receipt of
                stationery & question papers and their safe custody.
              </p>
              <p>
                7.8 No candidate shall normally be permitted to enter the
                examination hall after the commencement of the examination. The
                Superintendent of Examination Centre may, however, at his/her
                discretion; permit a candidate to enter the hall up to half an
                hour after commencement of the examination. However, no extra
                time will be allowed for such late admissions.
              </p>
              <p>
                7.9 No candidate shall be permitted to leave the examination
                hall before the lapse of one hour after commencement of
                examination. The Superintendent of Examination may in
                exceptional cases permit an examinee to leave the examination
                hall even before this period if in his/her judgement, continued
                presence of the examinee in the hall is not desirable in the
                interest of smooth conduct of examination or if a candidate
                becomes physically incapable of continuing the examination due
                to sudden illness.
              </p>
              <p>
                7.10 Amanuensis (writer) may be provided to a candidate if
                he/she is not in a position to write himself/herself subject to
                the discretion of the Superintendent of Examination, on the
                condition that no remuneration will be paid to such a writer by
                the Council and the writer will not be a person connected with
                the course, knowledgeable in the course or related to the
                student.
              </p>
              <p>
                7.11 Bonafide ‘dyslexic’ candidates shall be provided 25% extra
                time for theory examination.
              </p>
              <p>
                7.12 Every candidate shall be allotted an Examination Roll
                Number and an Identity/Admit Card issued by the Council with an
                attested photograph, which must be produced at every
                examination.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>8.0 ACTS OF MISCONDUCT & USE OF UNFAIR MEANS</h4>
              <p>
                8.1 The Superintendent of Examination shall report to the
                Controller of Examinations without delay and on the day of
                occurrence of each case where use of unfair means in Examination
                is suspected or detected with full details of evidence of
                actually what is noticed from the concerned person either the
                invigilators, examiners in practical examination or from any
                other source together with an explanation in writing from the
                candidate concerned. The report should indicate how, when (Date
                and Time) and by whom (Official on duty) the unfair means case
                was detected and what was actually noticed.
              </p>
              <p className="p2">
                Following shall be taken as ‘MALPRACTICE’ in the examination
              </p>
              <p className="sub-child">
                8.1.1 Candidate who is found in possession of any note-book(s)
                or notes or chits or any other unauthorised material concerning
                the subject pertaining to the examination paper.
              </p>
              <p className="sub-child">
                8.1.2 Anything written on any part of clothing, body, desk,
                table or any instrument such as setsquare, protractor, blotting
                paper and question paper etc.
              </p>
              <p className="sub-child">
                8.1.3 Possession of electronic gadgets such as Mobile phone, I
                Pad, Tablet, EarPhone, Head phone, Smart watch etc.
              </p>
              <p className="sub-child">
                8.1.4 Talking to another candidate in the examination hall or
                change of seat without the permission of Examination
                Superintendent.
              </p>
              <p className="sub-child">
                8.1.5 Consulting notes, books or any other material or outside
                person while going to toilet etc. outside the Examination Hall.
              </p>
              <p className="sub-child">
                8.1.6 Running away or swallowing or destroying any note or paper
                or material found with him/her.
              </p>
              <p className="sub-child">8.1.7 Impersonation.</p>
              <p className="sub-child">
                8.1.8 If the answer-books show that a candidate has received or
                given help to any other candidate through copying.
              </p>
              <p className="sub-child">
                8.1.9 Using obscene or offensive language in the answer book.
              </p>
              <p className="sub-child">
                8.1.10 Deliberately disclosing one’s identity or making any
                distinctive mark in the answer book for that purpose.
                <br></br>
                <br></br>
                The candidate found guilty of having adopted anyone or more of
                the above malpractice is liable to be penalised with a penalty
                by the National Council, which may vary from cancellation of the
                examination/expulsion up to maximum period of three years
                following the malpractice under issue.
              </p>
              <p>
                8.2 In case the candidate refuses to give a written statement,
                the fact of his refusal shall be recorded by the Superintendent
                and witnessed by at least two members of the Supervisory Staff
                present at the time of interrogation of the candidate by the
                Superintendent.
              </p>
              <p>
                8.3 A candidate found to be or suspected to be guilty of using
                unfair means in the examination shall be permitted to answer the
                remaining part of the question paper but on a separate answer
                book and the answer book in which the unfair means is suspected
                to have been committed shall be taken in possession by the
                Superintendent, who shall send both the answer books, marked I &
                II in the order in which these were used by the candidate to the
                Controller of Examinations with his/her detailed report and
                findings in a separate sealed cover. The candidate shall however
                be permitted to appear in the remaining examination(s) in
                subsequent papers(s).
              </p>
              <p>
                8.4 Even if a candidate is found having in his/her possession or
                accessible to him/her, papers, books or notes or material which
                do not relate to the subject of examination and which could not
                possibly be of any assistance to him/her, such cases must be
                reported to the Superintendent of Examination by the Invigilator
                with necessary papers for investigation.
              </p>
              <p>
                8.5 If a candidate is found talking to another candidate during
                the examination hours in the examination room, the
                Superintendent shall record the statement of both the candidates
                and the invigilator and send it to the Controller of
                Examinations with his/her comments. He/she shall also take
                action as prescribed in Rule 8.3 against both the candidates.
              </p>
              <p>
                8.6 The Superintendent of Examination shall report all cases of
                impersonation together with the evidence, which led to such
                conclusion and action taken.
              </p>
              <p>
                8.7 A candidate who refuses to obey the Superintendent of
                Examination and changes his/her seat without the permission of
                Examination Superintendent and/or creates disturbance of any
                kind during the examination and/or otherwise misbehaves in the
                examination hall shall be treated as a case of unfair means.
              </p>
              <p>
                8.8 A candidate found copying from notes written on any part of
                his/her clothing, body, desk or table or instrument like
                setsquares, protractors, scales etc. or who is found guilty of
                concealing, disfiguring, rendering illegible, swallowing or
                destroying any notes or papers or material found with him/her or
                found exchanging answer book or question paper with solution or
                talking to a person or consulting notes or books outside the
                Examination Hall, while going to the toilet or in the toilet
                shall be deemed to have used unfair means and action as proposed
                in Rule 8.3 above shall be taken against him/her.
              </p>
              <p>
                8.9 A candidate found taking assistance from an electronic
                gadget during the examination shall be deemed to have used
                unfair means and action as proposed in Rule 8.3 shall be taken.
              </p>
              <p>
                8.10 If a candidate fails/ refuses to deliver his/her answer
                book to the Supervisory staff before leaving the Examination
                hall, or mutilates or tears pages from the answer book, a report
                will be sent to the Controller of Examinations under separate
                sealed cover to this effect.
              </p>
              <p>
                8.11 If a candidate is found having exchanged the raw or semi or
                finished product/work or has presented a practical or class work
                or note book which does not belong to him/her in practical or
                viva-voce examination shall be deemed to have used unfair means.
                Submission of fake/ forged certificate of attendance for
                Industrial Training shall be treated as malpractice.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>9.0 PENALTY FOR UNFAIR MEANS</h4>
              <p>
                9.1 In case a candidate is found by the Superintendent of
                Examinations of having in his/ her possession or accessible to
                him/ her papers, books or notes or material which do not relate
                to the subject of the examination, no action be taken against
                the candidate. Such a case need not be reported to the
                Controller of Examinations and may be filed by the
                Superintendent of Examination after a warning to the candidate
                not to repeat such action.
              </p>
              <p>
                9.2
                <ul className="list-alpha">
                  <li>
                    A candidate found having in his/ her possession or
                    accessible to him/ her papers or chits or material relating
                    to the subject of the question paper shall be declared fail
                    in the subject.
                  </li>
                  <li>
                    A candidate found having in his/ her possession bulk
                    cheating material such as books, notes, chits or material
                    relating to the subject of the question paper shall be
                    declared fail in all the theory and practical subjects of
                    the semester.
                  </li>
                  <li>
                    A candidate found exchanging answer book or question paper
                    with solution or copying from unauthorised material shall be
                    declared fail in all the theory and practical subjects of
                    the semester.
                  </li>
                  <li>
                    A candidate found having in his/ her possession electronic
                    gadget(s) shall be declared fail in the subject.
                  </li>
                  <li>
                    A candidate found using/ copying from electronic gadget
                    shall be declared fail in all the theory and practical
                    subjects of the semester/ term.
                  </li>
                </ul>
              </p>
              <p>
                9.3 If a candidate voluntarily surrenders to the Superintendent
                during the course of examination, papers, books or notes or
                material in his/ her possession which are relevant to the
                examination, and these were not found or detected by a member of
                the supervisory staff, he/ she shall be disqualified as per
                provision under rule 9.2 a) or b) as the case may be.
              </p>
              <p>
                9.4 Candidate found talking to another candidate during the
                examination hours, in the examination room/ hall or outside or
                found changing his seat without the permission of Examination
                Superintendent, shall be asked to leave the examination room/
                hall and Controller of Examination informed.
              </p>
              <p>
                9.5 If an answer book shows that the candidate has received help
                from another candidate or to have copied from any paper, book or
                note or material or to have allowed any other candidate to copy
                from his/her answer book or to have taken the help from the
                notes written on any part of his/her clothing or body or table
                or desk or instruments like setsquares, protractors, scales
                etc., shall be declared fail in all the theory and practical
                subjects of the semester.
              </p>
              <p>
                9.6 If a candidate is found guilty of running away or swallowing
                or destroying any paper or note or material found on his/ her,
                the candidate shall be declared fail in all the theory and
                practical subjects of the semester.
              </p>
              <p>
                9.7 A candidate found in possession of a solution to a question
                set in the paper through connivance of any member of the
                supervisory or any other ancillary staff or some outside agency
                shall be declared fail in all the theory and practical subjects
                of the semester. Cases of such alleged misconduct on the part of
                supervisory or the other ancillary staff shall be reported by
                the Superintendent to the Administrative Authority concerned for
                necessary action.
              </p>
              <p>
                9.8 A candidate found guilty of smuggling in an answer book or a
                continuation sheet, or to take out an answer book or a
                continuation sheet, shall be disqualified for a maximum period
                of two academic years.
              </p>
              <p>
                9.9 A candidate found using obscene or abusive language in the
                answer book or found guilty of serious misconduct or
                misbehaviour towards the Superintendent or any
              </p>
              <p>
                member of the Supervisory staff shall be disqualified for the
                examination and debarred from appearing in the National
                Council’s examination for a period of two to five years
                according to the gravity of his/her misconduct.
              </p>
              <p>
                9.10 In case of impersonation, the candidate who is impersonated
                shall be disqualified for a minimum period of five academic
                years or for any such period as will be decided by the National
                Council. The Superintendent shall report the person who
                impersonates to the Police.
              </p>
              <p>
                9.11 If a candidate is found guilty of deliberately disclosing
                his/her identity or making distinctive mark in the answer book
                for that purpose or fails to deliver his/her answer
                book/continuation sheet before leaving the examination hall, he/
                she shall be declared fail in the subject.
              </p>
              <p>
                9.12 If a candidate is found having exchanged the raw or semi or
                finished product/work for an examination in practical, viva-voce
                or project work or presents to the examiner, a practical or
                class work notebook, project or tour report which does not
                belong to him/her, he/she shall be declared fail in all the
                theory and practical subjects of the semester. A student will be
                disqualified for a period of one year for submission of fake/
                forged certificate of attendance of Industrial Training or for
                any such period as decided by the Examination Committee.
              </p>
              <p>
                9.13 A candidate found to have attempted or trying to attempt
                personally or through another person to influence or pressurise
                an examiner, or any officer or official connected with the
                examinations, either at the institute or at the National
                Council, in any matter concerned with the examination shall be
                disqualified for a minimum period of one academic year.
              </p>
              <p>
                9.14 A candidate, found approaching or influencing or
                pressurising directly or indirectly a member of the examination
                committee or any officer or official of the Board, regarding
                his/her unfair means case shall be disqualified for one more
                year in addition to the punishment to be awarded according to
                the rules for him/her having used a particular type of unfair
                means as detailed in Rule 9.13 above.
              </p>
              <p>
                9.15 A candidate making false statement in any of the
                examination form shall be disqualified for a period of one year.
              </p>
              <p>
                9.16 A candidate found guilty of having adopted unfair means,
                but not covered by any of the above rules shall be punished
                according to the nature of the offence and decision of the
                National Council shall be final.
              </p>
              <p>
                9.17 According to the above rules, disqualification for one
                academic year shall mean that the candidate has failed in all
                subjects of the examination in which he/she has indulged/adopted
                unfair means and shall not be allowed to appear in the next term
                end examination. For example a candidate disqualified for one
                year for having used unfair means in the term end Examination
                2016 shall be declared fail and will not be allowed to appear in
                the term end examination 2017, and will be permitted to appear
                in the term end examination 2018 and onward if otherwise
                eligible under the rules.
              </p>
              <p>
                9.18 Candidate whose examinations are cancelled and who is
                dis-qualified from appearing in the National Council’s
                examination for certain number of semesters/ years, because of
                use of unfair means in the examination, may take admission as
                regular student upon completion of disqualification period. Such
                a candidate will be required to fulfil all conditions of
                eligibility, as laid down in Examination Rule 4. They will also
                have the option to appear as external candidate.
              </p>
            </div>
            <div class="NCHMExamRules">
              <h4>10.0 PROCEDURE FOR PENALTY FOR USE OF UNFAIR MEANS</h4>
              <p>
                10.1 On receipt of a report of use of unfair means from
                Superintendent/ Examiner or any other person, the Controller of
                Examinations shall issue notice to the candidate who used or is
                suspected to have used unfair means for providing opportunity to
                explain his/ her position before the Examination Committee
                either in writing or in person.
              </p>
              <p>
                10.2 The Examination Committee appointed by the National Council
                for Hotel Management & Catering Technology will then deal with
                and decide the case of alleged use of unfair means or misconduct
                by the candidate and the decision of the Examination Committee
                in all such cases of malpractice reported to it shall be final.
              </p>
              <p>
                10.3 If the Examination Committee is satisfied after due enquiry
                that the integrity of the examination has been violated at any
                examination centre as a result of enmass use of unfair means or
                unfair assistance referred to the examinees, the Examination
                Committee will order re-examination besides taking any other
                action that it may deem fit.
              </p>
              <p>
                10.4 The Examination Committee shall have the right to cancel
                the Diploma/ Certificate of a candidate without any prejudice to
                its year of award by notification in gazette, if it is
                discovered at a later stage that the candidate had furnished
                wrong or false information during the course of his/her study.
              </p>
              <p>
                10.5 The Examination Committee shall in normal circumstances
                finally decide about the reported cases of unfair means prior to
                the declaration of results.
              </p>
              <p>
                10.6 The condition/provision of minimum period of punishment as
                provided under the Examination Rules could be relaxed and the
                Examination Committee will be fully empowered to decide the
                period of disqualification/ debarring of candidates found to
                have committed malpractice in the National Council's
                Examination.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>11.0 MINIMUM PASS MARKS</h4>
              <p>
                11.1 To pass a theory subject a candidate must secure at least
                40% marks in Internal Assessment and End Term Examination
                together.
              </p>
              <p>
                11.2 To pass a practical subject a candidate must secure at
                least 50% marks in Internal Assessment and End Term Examination
                together.
              </p>
              <p>
                11.3 To pass industrial training, research project and term work
                a candidate must secure at least 50% marks in End Term
                Examination.
                <br></br>
                <br></br>
                <strong> Note:</strong> For the purpose of mark computation, a
                fraction of half or more than half shall be counted as one.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>12.0 INTERNAL ASSESSMENT</h4>
              <p>
                12.1 The Examination Committee of the National Council while
                determining the examination scheme of each semester/ term of the
                course will define clearly the distribution of marks fixed for
                theory and practical and internal assessment.
              </p>
              <p className="sub-child">
                12.2.1 The internal assessment shall denote performance of the
                candidate in incourse assessment held at institute and it shall
                be reckoned as 30% of the marks allotted to the subject in the
                final computing of results by the Council. Details of incourse
                assessment for each semester of course are indicated in the
                examination scheme.
              </p>
              <p className="sub-child">
                12.2.2 The Examination Committee will lay down detailed
                procedure for award of marks for the internal assessment in each
                subject (theory and practical) in order to ensure uniformity
                being followed in each of the affiliated Institutes conducting
                the course.
              </p>
              <p>
                12.3 A Committee consisting of at least three members
                constituted by the National Council Examination Committee may
                visit the Institutions to ensure the enforcement of procedures
                and fairness in award of internal assessment marks. This
                Committee may also examine the work of a cross section of the
                students in each class and in all subjects.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>13.0 MODERATION OF RESULTS</h4>
              <p>
                In cases, where the results of an examination, is affected,
                either on account of large failures in a subject(s) due to the
                question paper(s) set being not within the scope of the
                syllabus, or not within the reach of the students or on account
                of error, malpractice, fraud, improper conduct such matters will
                be placed before the Examination Committee appointed by the
                Board of Governors of National Council, for consideration,
                before the declaration of results.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>14.0 AWARD OF DIPLOMA & CERTIFICATE</h4>
              <p className="sub-child">
                14.1.1 Post Graduate and Graduate Degree: The two year degree in
                M.Sc in Hospitality Administration and three year degree in B.Sc
                in Hospitality & Hotel Administration have two components each
                i.e., NCHMCT and Degree awarding university. Each is governed by
                its own set of Examination and other Rules. The Degree to
                successful student is awarded by the University based on marks
                secured in both ccomponents’ i.e. NCHMCT and University.
              </p>
              <p className="sub-child">
                14.1.2 All other Diploma and Certificate courses: On passing all
                the examination(s) and fulfilling other conditions, if any,
                provided for in the Examination Scheme/ relevant Rules, a
                candidate will be awarded a Diploma/ Certificate in the
                particular branch of study through the Head of the Institution
                only.
              </p>
              <p>
                For the purpose of classification of Diploma/ Certificate, the
                following schedule will be adopted.
                <ul className="list-italic">
                  <li>
                    A candidate will be awarded a First Class with Distinction
                    if he/ she secures a minimum of 75% of the total marks.
                  </li>
                  <li>
                    A candidate will be awarded a First Class if he/ she secures
                    a minimum of 60% of the total marks.
                  </li>
                  <li>
                    A candidate will be awarded a Second Division if he/ she
                    secures a minimum of 50% of the total marks.
                  </li>
                  <li>
                    A candidate will be awarded a Diploma/Certificate in Pass
                    Class if he/she secures less than 50% of the total marks.
                  </li>
                  <li>
                    A candidate who passes all the examinations within the
                    prescribed duration of the course will be awarded a division
                    as per sub rule (i), (ii), (iii) & (iv) above.
                  </li>
                  <li>
                    Candidates who do not fall in any of the above categories
                    will be placed in Pass class.
                  </li>
                </ul>
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>15.0 PROMOTION, CARRY OVER AND RE-APPEAR</h4>
              <p className="p2">
                15.1.1 Two year Degree in M.Sc in Hospitality Administration:
              </p>
              <p>
                A student will be promoted on the basis of papers/ subjects
                passed in the NCHM component only.
                <ul className="list-alpha">
                  <li>
                    A student who has failed in not more than one paper/ subject
                    of first and second semester taken together shall be
                    eligible for promotion from first year to second year of the
                    course. Students who do not fulfil the criteria shall be
                    declared as fail.
                  </li>
                  <li>
                    A first year student, who has been declared fail, may take
                    admission as regular student in the failed semester. In that
                    case, he/ she will have to become eligible for the
                    particular examination again. Such a candidate will be
                    required to appear in all the papers/ subjects of the
                    semester afresh and fulfil all conditions of eligibility, as
                    laid down in Examination Rule 4. A failed student will also
                    have the option to appear as external student and appear in
                    the failed subject(s)/ paper(s).
                  </li>
                  <li>
                    A second year student, who has failed in one or more papers
                    / subjects, shall be placed under reappear category. Such a
                    student may take admission as regular student in any one of
                    the semesters or both. In that case, he/ she will have to
                    become eligible for the semester examination again. Such a
                    candidate will be required to appear in all the papers/
                    subjects of the semester afresh and fulfil all conditions of
                    eligibility, as laid down in Examination Rule 4. A reappear
                    student will also have the option to appear as external
                    student and appear in the failed subject(s)/ paper(s).
                  </li>
                </ul>
              </p>
              <p className="p2">
                15.1.2 Three year B.Sc degree in Hospitality & Hotel
                Administration:
              </p>
              <p>
                A student will be promoted on the basis of papers/ subjects
                passed in the NCHM component only.
                <ul className="list-alpha">
                  <li>
                    A student who has failed in not more than six papers/
                    subjects of first and second semester taken together shall
                    be eligible for promotion from first year to second year of
                    the course. Students who do not fulfil the criteria shall be
                    declared as fail. A fail student may take admission as
                    regular student in any one of the semesters or both. In that
                    case, he/ she will have to become eligible for the
                    particular examination again. Such a candidate will be
                    required to appear in all the papers/ subjects of the
                    semester(s) afresh and fulfil all conditions of eligibility,
                    as laid down in Examination Rule 4. A failed student will
                    also have the option to appear as external student and
                    appear in the failed subject(s)/ paper(s).
                  </li>
                  <li>
                    (i) B.Sc (Generic): A student who has failed in not more
                    than three papers/ subjects of first, second, third and
                    fourth semester taken together shall be eligible for
                    promotion from second year to third year of the course.
                    Students who do not fulfil the criteria shall be declared as
                    fail. A second year fail student may take admission as
                    regular student in any one of the semesters or both. In that
                    case, he/ she will have to become eligible for the
                    particular examination again. Such a candidate will be
                    required to appear in all the papers/ subjects of the
                    semester(s) afresh and fulfil all conditions of eligibility,
                    as laid down in Examination Rule 4. A failed student will
                    also have the option to appear as external student and
                    appear in the failed subject(s)/ paper(s).
                    <br></br> <br></br>
                    (ii) B.Sc (Specialisation): Similarly a student who has
                    failed in not more than five papers/ subjects of first,
                    second, third and fourth semester taken together shall be
                    eligible for promotion from second year to third year of the
                    course. Students who do not fulfil the criteria shall be
                    declared as fail. Such a second year fail student may take
                    admission as regular student in any one of the semesters or
                    both. In that case, he/ she will have to become eligible for
                    the particular examination again. Such a candidate will be
                    required to appear in all the papers/ subjects of the
                    semester(s) afresh and fulfil all conditions of eligibility,
                    as laid down in Examination Rule 4. A failed student will
                    also have the option to appear as external student and
                    appear in the failed subject(s)/ paper(s).
                  </li>
                  <li>
                    A third year student, who has failed in one or more papers /
                    subjects, shall be placed under reappear category. Such a
                    student may take admission as regular student in any one of
                    the semesters or both of third year. In that case, he/ she
                    will have to become eligible for the semester(s) examination
                    again. Such a candidate will be required to appear in all
                    the papers/ subjects of the semester afresh and fulfil all
                    conditions of eligibility, as laid down in Examination Rule
                    4. A reappear student will also have the option to appear as
                    external student and appear in the failed subject(s)/
                    paper(s).
                  </li>
                </ul>
              </p>
              <p className="p2">
                15.1.3 One and Half Year Post Graduate Diploma in Accommodation
                Operations & Management; One and Half Year Post Graduate Diploma
                in Dietetics & Hospital Food Service; One and Half Year
                Craftsmanship Certificate Course in Food Production and
                Patisserie; and Six Month Craftsmanship Certificate Course in
                Food & Beverage Service
              </p>
              <p>
                A student, who has failed in one or more papers / subjects of
                first and second semester together, shall be placed under
                reappear category. Such a student may take admission as regular
                student in any one of the semesters or both. In that case, he/
                she will have to become eligible for the semester(s) examination
                again. Such a candidate will be required to appear in all the
                papers/ subjects of the semester afresh and fulfil all
                conditions of eligibility, as laid down in Examination Rule 4. A
                reappear student will also have the option to appear as external
                student and appear in the failed subject(s)/ paper(s).
              </p>
              <p className="p2">
                15.1.4 One and Half Year Diploma in Food Production; One and
                Half Year Diploma in Food & Beverage Service; One and Half Year
                Diploma in Housekeeping; One and Half Year Diploma in Front
                Office Operations; and One and Half Year Diploma in Bakery &
                Confectionery.
              </p>
              <p>
                A student, who has failed in one or more papers / subjects,
                shall be placed under reappear category. Such a student may take
                admission as regular student. In that case, he/ she will have to
                become eligible for the Annual End Term examination again. Such
                a candidate will be required to appear in all the papers/
                subjects of the year afresh and fulfil all conditions of
                eligibility, as laid down in Examination Rule 4. A reappear
                student will also have the option to appear as external student
                and appear in the failed subject(s)/ paper(s).
              </p>
              <p className="sub-child">
                15.2.1 All reappear/ failed candidates, who opt as external
                candidates, will be allowed to appear in the next examination in
                the failed subject(s)/ paper(s). The incourse assessment marks
                of failed subject(s) and the semester marks obtained in pass
                subjects will be carried forward for the next examination.
                Student who has to reappear in the odd semester (I/ III/ V)
                paper/ subject can appear in the odd semester examination.
                Similarly student who has to reappear in the even semester (II/
                IV/ VI) paper/ subject can appear in the even semester
                examination. Such candidates will have to fulfill all the
                conditions as per Rule 11 for them to be declared as having
                passed a particular course.
              </p>
              <p className="sub-child">
                15.2.2 Students appearing in end term exam of a theory subject
                can also appear for incourse assessment theory exam against a
                fee. Incourse assessment for a practical subject is a continuous
                assessment during the semester/term and as such this provision
                will not be available.
              </p>
              <p>
                15.3 In case the curriculum has undergone modification or
                change, the re-appear/ fail candidates will necessarily have to
                undertake the examination as per the new scheme.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>
                16.0 DISQUALIFICATION OF CANDIDATES DUE TO REPEATED FAILURE
              </h4>
              <p>
                16.1 No person shall be allowed to remain a student in any class
                of any course affiliated to the National Council for a period
                longer than the maximum duration as at rule 4.7, irrespective of
                the number of examinations taken by him during the period or
                irrespective of the cause of his/ her not qualifying for
                promotion to the next higher class or as the case may be.
              </p>
              <p>
                16.2 Beyond the maximum permitted period as at 4.7, a candidate
                shall appear only after the specific permission from the
                National Council and/ or University as the case may be. This
                permission, not exceeding two in any case, shall be granted on a
                year to year basis against a fee. This permission shall not be
                granted if the course(s) has/ have undergone material change or
                is/ are wound up.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>17.0 ANNOUNCEMENT OF RESULTS</h4>
              <p>
                As soon as practicable after the conclusion of an examination,
                the Controller of Examinations shall declare the results of the
                examination in accordance with the foregoing rules.
                <br></br>
                <br></br>
                Marks in respect of Industrial Training, Research Project,
                Practical, Term work and Incourse Assessment received after the
                due date announced by the National Council will not be
                considered for that examination and results announced
                accordingly.
              </p>
            </div>
            <div className="NCHMExamRules">
              <h4>18.0 VERIFICATION OF MARKS</h4>
              <p>
                The National Council will after the declaration of results
                entertain applications from candidates for verification of
                Theory & Practical on payment of prescribed fee up to 15 days
                from the date of declaration of result. Applications received
                thereafter will not be considered.
                <br></br>
                <br></br>
                The period of 15 days will be counted from the date the results
                are officially declared by the National Council and not from the
                date on which the results are announced by the
                institute/actually published in the Newspapers or official
                gazette. It will be the candidate’s responsibility to see that
                the application for verification reaches the office of the
                Controller of Examination by the due date after which no
                application will be entertained.
                <br></br>
                <br></br>
                Each application should be sent through the Principal and shall
                be accompanied with a Demand Draft drawn in favour of National
                Council for Hotel Management and Catering Technology NOIDA
                payable at NOIDA.
              </p>
              <p>
                18.1
                <ul className="list-alpha">
                  <li>
                    The National Council will on receipt of such application for
                    verification of marks in theory paper, examine whether the
                    answer by the candidate in those answer papers have been
                    assessed by the examiner and whether the marks as
                    transcribed to the mark sheets and finally to the result
                    sheets are correct.
                  </li>
                  <li>
                    The National Council will, on receipt of such application
                    for verification of marks in practical and internal
                    assessment, examine the award sheet submitted by the
                    examiner(s) so as to see whether the total of marks assigned
                    under different sub- heads and the marks transcribed from
                    the award sheet to the result-sheet are correct.
                  </li>
                  <li>
                    Verification of marks will not be done in the presence of
                    the candidate. In case where there is an omission of
                    whatsoever nature affecting the results, National Council
                    will get it rectified, and declares the result consequent
                    upon such rectification.
                  </li>
                </ul>
              </p>
              <p>
                18.2 All cases of verification of answer book(s) shall be
                decided and communicated to the institute within 60 days of
                declaration of results.
              </p>
              <p>
                18.3 Answer sheets shall be destroyed/ disposed of, as per
                procedure laid down by the Examination Committee after 90 days
                of result declaration.
              </p>
              <p>
                18.4 All records pertaining to any student shall be maintained
                by the institute for at least two academic years after the
                academic year of occurrence of activity e.g. records pertaining
                to 2016-17 academic year shall be maintained up to the end of
                2018-19 academic year.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NCHMExamRules;
