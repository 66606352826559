import React, { Component, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import LOGOf from "../assets/images/LogoFreeCrop.png";
import Logogit1 from "../assets/gif/chep-cap-gif.gif";
import Logogit2 from "../assets/gif/coocking-stick-gif.gif";
import Logogit3 from "../assets/gif/gratution-cap-gif.gif";
import { navigate, Link } from "@reach/router";


import banerimg10 from "../assets/images/bannerimg.png";


import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";


const Header = () => {
  const [toggeled, setToggeled] = useState(false);
  const [notHomePage,setNotHomePage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrMsg, setNumberErrMsg] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [locationErrMsg, setLocationErrMsg] = useState(false);
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [number, setNumber] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const triggerOnWidth=()=> {
    if (window.innerWidth < 600) {
      setToggeled(!toggeled);
    } else {
     
    }
  }
 const scholarshipTriggerOnWidth=()=>{
   setShowModal(true)
  if (window.innerWidth < 600) {
  
    setToggeled(!toggeled);
  } else {
   
  }
 }
 const valid = () => {
  if (name.length < 2) {
    setNameError(true);
    setNameErrMsg("This is a mandatory field, please fill");
    return false;
  }
  if (emailId.length < 2) {
    setEmailError(true);
    setEmailErrMsg("This is a mandatory field, please fill");
    return false;
  }

  if (number.length < 2) {
    setNumberError(true);
    setNumberErrMsg("This is a mandatory field, please fill");
    return false;
  }

  if (location.length < 2) {
    setLocationError(true);
    setLocationErrMsg("This is a mandatory field, please fill");
    return false;
  } else {
    return true;
  }
};
const submitMailDetails = () => {
  setNumberError(false);
  setNameError(false);
  setEmailError(false);
  setLocationError(false);
  console.log("name", name.length);
  if (valid()) {
    setLoading(true);
    const payload = {
      name: name,
      email: emailId,
      phone: number,
      location: location,
    };
    axios
      .post("https://ihminfo.co.in/backend/MasterApi/saveInfo", payload)
      .then((response) => {
        setLoading(false);
        setName("");
        setEmailId("");
        setNumber("");
        setLocation("");
        console.log(response.data.result);
        if (response.data.result === 1) {
          setSuccessMsg(response.data.message);
          navigate("/thank_you");
          // Testing(response.data.message);
        }
      });
  } else {
  }
};


  setInterval(() => {
    let path= window.location.pathname;
    if(path !=="/"){
      console.log(path)
setNotHomePage(true);
    }
    else {
setNotHomePage(false)
    }
  }, 1000);

  

  return (<>
  {showModal ? (
        <section id="cuspanel2" className="vh-100 popuopz lunch-popup lunch-popup2" style={{zIndex: "99999999999999999999999999999999"}}>
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-10 mb-5">
                <div className="card" style={{ borderRadius: "1rem" }}>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="closebox"
                  ></button>
                  <div className="row g-0">
                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                      <img
                        src={banerimg10}
                        alt="login form"
                        className="img-fluid"
                        style={{
                          borderRadius: "1rem 0 0 1rem",
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                      <div className="card-body p-4 p-lg-5 text-black">
                        <form>
                          <div className="d-flex align-items-center mb-3 pb-0">
                            {/* <a className="pop-logo" href="#">
                              {" "}
                              <img src={LOGOf} alt="logo" />
                            </a> */}
                            <span className="h2 fw-bold mb-0">IHMs India</span>
                          </div>

                          <h5
                            className="fw-normal mb-2 pb-3"
                            style={{ letterSpacing: "1px" }}
                          >
                            TO GET MORE INFO PLEASE CONTACT
                          </h5>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Name
                            </label>
                            <input
                              type="text"
                              value={name}
                              id="form2Example27"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              required
                            />
                            {nameError ? (
                              <span className="fromerror modalv2">
                                {nameErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example17">
                              Email address
                            </label>
                            <input
                              type="email"
                              value={emailId}
                              id="form2Example17"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setEmailId(e.target.value);
                              }}
                            />
                            {emailError ? (
                              <span className="fromerror modalv2">
                                {emailErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Phone No.
                            </label>
                            <input
                              type="number"
                              value={number}
                              maxlength="10"
                              id="form2Example27"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setNumber(e.target.value);
                              }}
                            />
                            {numberError ? (
                              <span className="fromerror modalv2">
                                {numberErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Location
                            </label>
                            <input
                              type="text"
                              value={location}
                              id="form2Example27"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setLocation(e.target.value);
                              }}
                            />
                            {locationError ? (
                              <span className="fromerror modalv2">
                                {locationErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="pt-1 mb-2">
                            <button
                              className="btn btn-dark btn-lg btn-block"
                              type="button"
                              onClick={submitMailDetails}
                            >
                              {loading ? (
                                <ClipLoader color="white" />
                              ) : (
                                <span>Submit</span>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
         <nav className="navbar navbar-expand-lg b-color">
      <div className="container-fluid">
        <section id="heroween" className={notHomePage? "homeclass" : "nothomeclass"}>
          <div className="logo-box">
            <Link to="/" >
              <a className="loiconl navbar-brand navbar-brand-v2" href="/">
                {/* <img src={LOGOf} alt="logo" /> */}
                IHM India
                <span id="lgif">
                  <img src={Logogit1} alt="logo" />
                  <img src={Logogit2} alt="logo" />
                  <img src={Logogit3} alt="logo" />
                </span>
              </a>
            </Link>

            <div className="borderbtn">
              <button
                className="tglbtn"
                onClick={() => {
                  setToggeled(!toggeled);
                }}
              >
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
              </button>
              <div className={!toggeled ? "navdiv" : "navclps"}>
                <div className=" new-nav2 " id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        target="_blank"
                        href=" https://www.facebook.com/ihm.info"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        target="_blank"
                        href=" https://www.instagram.com/ihm.info/"
                      >
                        <i class="bi bi-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className=" new-nav " id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item"  onClick={triggerOnWidth}>
                      <Link
                        className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/"
                      >
                        Home
                      </Link>{" "}
                    </li>
                    <li className="nav-item"  onClick={triggerOnWidth}>
                      <Link
                        className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/courses"
                      >
                        Courses
                      </Link>{" "}
                    </li>
                    <li className="nav-item"  onClick={triggerOnWidth}>
                      <Link
                        className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/rules"
                      >
                        Rules{" "}
                      </Link>
                    </li>
                    <li className="nav-item" onClick={triggerOnWidth}>
                      <Link
                        className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/nchm_exam_rules"
                      >
                        NCHM Exam Rules{" "}
                      </Link>
                    </li>
                    {/* <li className="nav-item" onClick={scholarshipTriggerOnWidth} >
                     
                      <span style={{fontSize: "16px",
                                     textShadow: '0 2px 3px  ghostwhite' ,       color: '#000 '}}>
                                       Scholarship</span>  
                    
                    </li> */}
                    {/* <li className="nav-item" onClick={triggerOnWidth}>
                      <Link
                        className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/nchmct-jee-exam"
                      >
                        NCHMCT JEE
                      </Link>
                    </li>
                    <li className="nav-item" onClick={triggerOnWidth}>
                      <Link
                        className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/nchmct-study-material"
                      >
                        NCHMCT Study Material
                      </Link>
                    </li>
                    <li className="nav-item" onClick={triggerOnWidth}>
                      <Link
                        className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/nchmct-syllabus"
                      >
                        NCHMCT Syllabus
                      </Link>
                    </li> */}
                    <li onClick={triggerOnWidth}>
                    <div class="dropdown">
  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    NCHMCT
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="/nchmct-jee-exam">NCHMCT JEE</a></li>
    <li><a class="dropdown-item" href="/nchmct-syllabus">NCHMCT Syllabus</a></li>
    <li><a class="dropdown-item" href="/nchmct-exam-centres">NCHMCT Exam Centres</a></li>

    <li><a class="dropdown-item" href="/nchmct-study-material">NCHMCT Study Material</a></li>
    
  </ul> 
</div>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </nav>
  </>
 
  );
};

export default Header;
