import React,{useEffect} from 'react';

function Coourses() {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, [])
  return (
      <>
        <div className="container  courses-container">
            <h1 className='courseshead'>Full Term Courses</h1>
            <div className="row">
                <div className="col-md-12">
                    <div className="courses">
                        <div className='row'>
                            <div className=' col-lg-9 col-md-8 thr1'>
                                <h2 >Bachelor of Science in Hospitality and Hotel Administration</h2>
                            </div>
                            <div className='col-lg-3 col-md-4 thr2'>
                                <h3>Duration : 3 Years</h3>
                                <h3>Qualification : 10+2 or Equivalent</h3>
                            </div>
                        </div>
                        <p>The Bachelor of Science programme in Hospitality and Hotel Administration is offered by the
National Council and the Indira Gandhi National Open University. The Three – Year Programme
equips trainees with the required skills, knowledge and attitude to efficiently discharge
supervisory responsibilities in the hospitality sector. The Bachelor of Science programme
comprise in-depth laboratory work for students to acquire the required knowledge and skill
standards in the operational areas of Food Production, Food and Beverage Service, Front Office
Operation and House Keeping. It also imparts substantial managerial inputs in subject such as
Sales and Marketing, Financial Management, Human Resource Management, Hotel and Catering
Law, Property Management, Entrepreneurship Development and other related subjects.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="courses">
                        <div className='row'>
                            <div className='col-lg-9 col-md-8 thr1'>
                                <h2 >Masters of Science (M.Sc.) in Hospitality Administration</h2>
                            </div>
                            <div className='col-lg-3 col-md-4 thr2'>
                                <h3>Duration : 2 Years</h3>
                                <h3>Qualification : B.Sc. (H.H.A)</h3>
                            </div>
                        </div>
                        <p>The M.Sc. Program is offered jointly by NCHMCT and IGNOU. The graduate from M.Sc
program will be able to perform at a higher level being able to deal with a greater complexity of
material. The depth of study provided by the program will enable the graduates to become better
managers with a depth of understanding and width of synthesis abilities. The program
emphasizes on the development of the student’s ability to acquire knowledge and understanding
through pursuing a philosophy of independent learning and research. The Program is a judicious
blend of intensive classroom contact, self-learning, industry mentorship and counseling sessions.
With the built-in industry mentorship the scholars learns current industry trends which offers
scope for building case studies and writing dissertation in the selected areas of specialization.
The program will develop spirit of scholarly enthusiasm by providing in depth and challenging
learning experience. The program will provide a platform to develop abilities required by
effective teachers, thinkers, authors and researchers.
The course design and structure has been arrived at by pooling the expertise of Hospitality
Education of NCHMCT and the Business Education of IGNOU. This Program has specific focus
on the services sector as against majority of MBA programs which concentrate on merchandise
and manufacturing businesses.</p>
                    </div>
                </div>
            </div>
            
        </div>
            {/* <Footer /> */}
        </>
  )
}


export default Coourses;