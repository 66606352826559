import React from "react";
import Home from "./components/home";
import Thankyou from "./components/ThankYou";
import Coourses from "./components/Courses";
import "./App.css";
import Rules from "./components/Rules";
import { Router } from "@reach/router";
import NCHMExamRules from "./components/NCHMExamRules";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NchmctJEE from "./pages/nchmctJee";
import StudyMaterial from "./pages/nchmctStudyMaterials";
import Syllabus from "./pages/nchmctSyllabus";
import ExamCentres from "./pages/nchmctJeeExamCentres";
import Privacy from "./pages/privacy";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Home path="/" />
        <Thankyou path="/thank_you" />
        <Coourses path="/courses" />
        <Rules path="/rules" />
        <NCHMExamRules path="/nchm_exam_rules" />
        <NchmctJEE path="/nchmct-jee-exam" />
        <StudyMaterial path="/nchmct-study-material" />
        <Syllabus path="/nchmct-syllabus" />
        <ExamCentres path="/nchmct-exam-centres" />
        <Privacy path="/privacy-policy" />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
