import React, { Component, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import LOGOf from "../assets/images/LogoFreeCrop.png";
import Logogit1 from "../assets/gif/chep-cap-gif.gif";
import Logogit2 from "../assets/gif/coocking-stick-gif.gif";
import Logogit3 from "../assets/gif/gratution-cap-gif.gif";

import banergif from "../assets/gif/baner/banergit1.gif";

import bannervideo from "../assets/videos/Films of NCHM.mp4";
import HomeSlider from "./homeSlider.js";
import FormContent from "./formContent.js";
import Gallery from "./gallery.js";
import banerimg10 from "../assets/images/bannerimg.png";

import TableSections from "./tableSections";
import Footer from "./Footer";
import DialogModal from "./dialogModal";
import Swal from "sweetalert2";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { navigate, Link } from "@reach/router";

function Home() {
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [number, setNumber] = useState("");
  const [location, setLocation] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrMsg, setNumberErrMsg] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [locationErrMsg, setLocationErrMsg] = useState(false);

  const [windowSize, setWindowSize] = useState("");
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    var viewport_width = window.innerWidth;
    setWindowSize(viewport_width);
  }, []);
  //  njjj

  console.log("go", windowSize);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 900) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const Testing = (successText) => {
    const Toast = Swal.mixin({
      position: "center",
      showConfirmButton: false,
      timer: 4000,

      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      background: "white",
      iconColor: "green",
      color: "green",
      title: successText,
    });
  };
  const valid = () => {
    if (name.length < 2) {
      setNameError(true);
      setNameErrMsg("This is a mandatory field, please fill");
      return false;
    }
    if (emailId.length < 2) {
      setEmailError(true);
      setEmailErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (number.length < 2) {
      setNumberError(true);
      setNumberErrMsg("This is a mandatory field, please fill");
      return false;
    }

    if (location.length < 2) {
      setLocationError(true);
      setLocationErrMsg("This is a mandatory field, please fill");
      return false;
    } else {
      return true;
    }
  };
  const submitMailDetails = () => {
    setNumberError(false);
    setNameError(false);
    setEmailError(false);
    setLocationError(false);
    console.log("name", name.length);
    if (valid()) {
      setLoading(true);
      const payload = {
        name: name,
        email: emailId,
        phone: number,
        location: location,
      };
      axios
        .post("https://ihminfo.co.in/backend/MasterApi/saveInfo", payload)
        .then((response) => {
          setLoading(false);
          setName("");
          setEmailId("");
          setNumber("");
          setLocation("");
          console.log(response.data.result);
          if (response.data.result === 1) {
            setSuccessMsg(response.data.message);
            navigate("/thank_you");
            // Testing(response.data.message);
          }
        });
    } else {
    }
  };

  return (
    <div>
      <div id="feedback">
        <a onClick={openModal}>Quick Enquiry</a>
      </div>

      {showModal ? (
        <section id="cuspanel2" className="vh-100 popuopz lunch-popup lunch-popup2">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-10 mb-5">
                <div className="card" style={{ borderRadius: "1rem" }}>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="closebox"
                  ></button>
                  <div className="row g-0">
                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                      <img
                        src={banerimg10}
                        alt="login form"
                        className="img-fluid"
                        style={{
                          borderRadius: "1rem 0 0 1rem",
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                      <div className="card-body p-4 p-lg-5 text-black">
                        <form>
                          <div className="d-flex align-items-center mb-3 pb-0">
                            {/* <a className="pop-logo" href="#">
                              {" "}
                              <img src={LOGOf} alt="logo" />
                            </a> */}
                            <span className="h2 fw-bold mb-0">IHMs India</span>
                          </div>

                          <h5
                            className="fw-normal mb-2 pb-3"
                            style={{ letterSpacing: "1px" }}
                          >
                            TO GET MORE INFO PLEASE CONTACT
                          </h5>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Name
                            </label>
                            <input
                              type="text"
                              value={name}
                              id="form2Example27"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              required
                            />
                            {nameError ? (
                              <span className="fromerror modalv2">
                                {nameErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example17">
                              Email address
                            </label>
                            <input
                              type="email"
                              value={emailId}
                              id="form2Example17"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setEmailId(e.target.value);
                              }}
                            />
                            {emailError ? (
                              <span className="fromerror modalv2">
                                {emailErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Phone No.
                            </label>
                            <input
                              type="number"
                              value={number}
                              maxlength="10"
                              id="form2Example27"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setNumber(e.target.value);
                              }}
                            />
                            {numberError ? (
                              <span className="fromerror modalv2">
                                {numberErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-outline mb-2">
                            <label className="form-label" for="form2Example27">
                              Location
                            </label>
                            <input
                              type="text"
                              value={location}
                              id="form2Example27"
                              className="form-control form-control-lg"
                              onChange={(e) => {
                                setLocation(e.target.value);
                              }}
                            />
                            {locationError ? (
                              <span className="fromerror modalv2">
                                {locationErrMsg}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="pt-1 mb-2">
                            <button
                              className="btn btn-dark btn-lg btn-block"
                              type="button"
                              onClick={submitMailDetails}
                            >
                              {loading ? (
                                <ClipLoader color="white" />
                              ) : (
                                <span>Submit</span>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* 
      <nav className="navbar navbar-expand-lg b-color">
        <div className="container-fluid">
          <section id="heroween">
            <div className="logo-box">
              <a className="loiconl navbar-brand navbar-brand-v2">
                <img src={LOGOf} alt="logo" />
                IHM India
                <span id="lgif">
                  <img src={Logogit1} alt="logo" />
                  <img src={Logogit2} alt="logo" />
                  <img src={Logogit3} alt="logo" />
                </span>
              </a>

             <div>
             <button className="tglbtn" onClick={()=> {
               setToggeled(!toggeled);
             }}>
                  <div class="bar1"></div>
  <div class="bar2"></div>
  <div class="bar3"></div>
                  </button>
             <div  className= {!toggeled? "navdiv" :  "navclps"}>
                <div className=" new-nav2 " id="navbarSupportedContent">
                  
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        target="_blank"
                        href=" https://www.facebook.com/ihm.info"
                      >
                        <i class="bi bi-facebook"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        target="_blank"
                        href=" https://www.instagram.com/ihm.info/"
                      >
                        <i class="bi bi-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className=" new-nav " id="navbarSupportedContent">
                 
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/"
                      >
                        Home
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/courses"
                      >
                        Courses
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/rules"
                      >
                        Rules{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="navlink"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/nchm_exam_rules"
                      >
                        NCHM Exam Rules{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
             </div>
            </div>
          </section>
        </div>
      </nav> */}

      <div class="benner bennerv2">
        {windowSize > 656 ? (
          <video autoplay="autoplay" loop autoPlay muted>
            <source src={bannervideo} type="video/mp4" />
          </video>
        ) : (
          <div>
            <img id="banergifimg" src={banergif} alt="img" />
          </div>
        )}

        <div className="buttomstylebox"></div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 explainbox">
            <div className="boxitem boxitemv2">
              <h2>Courses offered by IHMs in India</h2>
              <p>
                Academics for  BSc Hospitality &amp; Hotel Administration  and
                ten other courses of study offered at IHMs are run and regulated
                by National Council for Hotel Management &amp; Catering
                Technology (NCHMCT) which is an autonomous body that falls under
                the ambit of Ministry of Tourism, Government of India.
                <br />
                IHMs offers undergraduate, postgraduate and diploma programmes
                to aspirants in the field of hospitality and travel as well as 
                baking and confectionery . Some popular hotel management courses
                offered at IHMs are mentioned in the table below:
              </p>

              <section>
                <p>
                  IHM Admission Process Admissions in different courses offered
                  at IHMs is on the basis of separate eligibility and admission
                  criteria.
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <HomeSlider />
      <FormContent />
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          {" "}
          &#8679;
        </button>
      )}

      <Gallery />
      <TableSections />

      <div>
        <DialogModal />
      </div>
    </div>
  );
}

export default Home;
