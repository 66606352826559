import React, {useEffect} from "react";
import Footer from "./Footer";

function Rules() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])
  
  return (
    <div style={{marginTop:"60px"}}>
      <div  className="container Rules-container">
        <div className="row ma-s">
          <div className="col-md-12">
            <div className="Rules">
              <h1 className="text-center">General Rules</h1>
              <div className="rulecontain">
                <p>
                  <strong> Working Hours:</strong> The working hours of the
                  institute are 9 a.m. To 5.30 p.m. for five days a week. Visit
                  to industrial and large scale catering and other
                  establishments are arranged during the term.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong> Institute Regulations:</strong> 
                </p>
                <ul>
                  <li>
                    Every Student must always carry his/her identity card which
                    will be supplied by the Institute office on payment.
                  </li>
                  <li>
                    The students must not absent themselves from any Institute
                    activity without the priorpermission of the Principal.
                  </li>
                  <li>
                    The Institute may take disciplinary action against a student
                    whose conduct is not satisfactory and the fees paid will be
                    forfeited.
                  </li>
                  <li>
                    Smoking &amp; Drinking is strictly prohibited in all areas
                    of the Institute.
                  </li>
                  <li>
                    No student should communicate any information or write about
                    matters dealing with Institute administration in the press.
                  </li>
                  <li>
                    Absence without leave is considered a breach of discipline.
                  </li>
                  <li>
                    The Institute authorities cannot accept any liability in
                    respect of any accident caused to a student while engaged in
                    practical work or due to any other causes.
                  </li>
                  <li>
                    Any change of address should be immediately notified for
                    Institute’s records.
                  </li>
                  <li>
                    Matters not covered by the existing rules will rest at the
                    absolute discretion of theinstitute.
                  </li>
                </ul>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>Medical Facilities:</strong> Working in the hotel
                  industry demands not only a sound mind but a robust health
                  also. To ensure that a student remains healthy all through his
                  learning period, a qualified medical practitioner visits the
                  Institute twice a week. The doctor is available at the hostels
                  on the weekends too. In case of any sudden illness, he is
                  always available on call. Standard First aid facility is also
                  made available to the students in the practical labs.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>Other Activities:</strong> Sports &amp; Games, Food
                  Fests, Cultural Programmes, Chef Contests (Inter &amp; Intra
                  Institutes) and social activities like Blood Donation Camps
                  etc. are organized from time to time.{" "}
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>Fees:</strong> Fees are payable in advance. All fees
                  are payable through online Banking facility, both digested
                  &amp; non digested (paid by auto generated e-chalan through
                  Demand Draft), as available at the Institute’s website 
                  <a target="_blank" href=" https://www.ihmkol.org">
                    www.ihmkol.org
                  </a>
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>Attendance Rules &amp; Regulations:</strong> A
                  candidate must have attended not less than 70 per cent of
                  classes in any individual subject theory or practical taken
                  separately during the session last attended and minimum of 75
                  per cent of the aggregate (effective teaching hours /contact
                  hours). Attendance in lectures, practical and tutorials shall
                  be counted from the date of commencement of teaching after
                  summer vacation. When on account of bonafide illness
                  (supported by a Medical Certification from a Registered
                  Medical Practitioner produced by the candidate at the time of
                  illness immediately thereafter) or for any other reason deemed
                  sufficient by the Head of the Institution the total attendance
                  of a candidate falls short up to a maximum of 10 per cent of
                  the total working days, the Head of the Institution shall be
                  competent to condone such deficiency in attendance and permit
                  such a candidate of appearing for the examination if otherwise
                  eligible. Notwithstanding the forwarding of forms of
                  applications for admission to examination and payment of
                  examinations fees through the Head of the Institution and
                  allocation of Examination Seat Numbers by the National
                  Council, the Head of the Institution shall be competent to
                  withdraw such applications in respect of those candidates who
                  do not fulfil conditions laid down, at any time before the
                  commencement of examination and to disallow those candidates
                  from appearing for the examination.
                </p>
              </div>
              <h6>Exam Rules.</h6>
              <h6>PDF Attatched.</h6>
              <h1 className="text-center">Attendance Rules</h1>
              <div className="rulecontain">
                <h3>
                  National Council for Hotel Management &amp; Catering
                  Technology, Noida – ATTENDANCE RULES
                </h3>
                <p>
                  Attendance rules as mentioned below are part of Examination
                  Rules as amended up to July 2011 (Rule 4) and are applicable
                  to all the regular students for:
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>
                    Eligibility For Admission To The Examination –
                  </strong>
                  Candidates from Institutions affiliated to the National
                  Council for Hotel Management and Catering Technology and
                  undergoing courses of instructions as approved by the National
                  Council only will be entitled to appear for the Diploma or
                  other examinations subject to the following conditions;
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>4.1</strong> In order to be eligible to take the
                  Council’s examinations (any semester or course), a candidate
                  must have;
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>4.1.1</strong> been on the roll of an affiliated
                  institution for one full academic session (period of study) in
                  the course for which he/she wants to take the examination;
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>4.1.2 </strong>submitted his/her application to the
                  Controller of Examinations through the head of the institution
                  last attended or any other authority nominated by the National
                  Council for Hotel Management and Catering Technology for this
                  purpose;
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>4.1.3 (a)</strong> A student is expected to attend all
                  classes (100%) i.e. theory, practical, tutorial and industrial
                  training.
                  <br></br>
                  <strong>(b)</strong> Attendance in all cases shall be counted
                  from the date of commencement of classes.
                  <br></br>
                  <strong>(c)</strong> Must have attended not less than 75% of
                  aggregate of all the classes conducted.
                  <strong> *NOTE: </strong> Industrial training shall not form
                  part of above and will be treated separately.
                  <br></br>
                  <br></br>
                  Every period of lecture, tutorial, practical, project and
                  workshop work as applicable shall be counted as one unit for
                  the purpose of counting attendance
                </p>
              </div>

              <div className="rulecontain">
                <p>
                  <strong> i)</strong> e.g. if a class covers four periods at a
                  time, four units of attendance will be counted.
                </p>
              </div>

              <div className="rulecontain">
                <p>
                  <strong> ii)</strong> Each candidate should have carried out
                  laboratory, practical assignment, project work etc. prescribed
                  in the course taken by him/her to the satisfaction of the Head
                  of the Institution and certified by the Head of that
                  Institution as fit for appearing at the examination as regards
                  progress, conduct and character provided that such candidates
                  have not been debarred from appearing for any examination held
                  by any Government or constituted statutory examination
                  authority in India at the time when examinations are held by
                  the National Council for Hotel Management &amp; Catering
                  Technology.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong> iii)</strong> A student engaged in extra-curricular
                  and co-curricular activities, with the permission of the
                  Principal (Food exhibitions, Chef Competition, education tour
                  etc.) shall be treated as present for the purpose of counting
                  attendance in accordance with the timetable. Such absence from
                  class, under any circumstances, shall not exceed more than 10%
                  of total classes held during the session.
                  <strong>(a)</strong> When on account of bonafide illness
                  (supported by a Medical Certificate from a Registered Medical
                  Practitioner produced by the candidate at the time of illness
                  or immediately thereafter) or for any other reason deemed
                  sufficient by the Head of the Institution (e.g. death in
                  family), the total attendance of a candidate falls short up to
                  a maximum of 10 per cent of the total working days, the Head
                  of the Institution shall be competent to condone such
                  deficiency in attendance and permit such a candidate to appear
                  for the examination if otherwise eligible.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>4.1.4 </strong> A candidate who is disallowed from
                  appearing for an examination for not fulfilling the conditions
                  at 4.1.3 (a) and/or (b) above shall be required to fulfil all
                  the conditions afresh, before being entitled to appear for the
                  examination.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>4.2</strong>A candidate, who fulfils the conditions at
                  para 4.1 above but does not appear for the examination, may at
                  his/her option appear for the subsequent examination as an
                  external student. In such cases the internal assessment put in
                  shall be preserved by the Head of the Institution and
                  presented at the subsequent examination.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>4.3</strong> Attendance of the students, provisionally
                  promoted to the next higher class, shall be counted from the
                  date of commencement of session.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>4.4</strong> A candidate having passed an examination
                  shall not be entitled to re-appear for the same examination.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>4.5.1</strong> All applications for admission to the
                  examination, shall be accompanied by the necessary fee
                  prescribed by the National Council for Hotel Management and
                  forwarded through the Head of the Institution on or before the
                  last date notified by the National Council every year. An
                  additional late fee as prescribed in Rule 5 shall be charged
                  for each late application received in the National Council’s
                  office not later than 15 days after the notified last date and
                  upto 30 days before the commencement of examination. The Chief
                  Executive Officer, National Council for Hotel Management &amp;
                  Catering Technology shall, however, be competent to accept
                  such late applications without any late fees even after the
                  notified date in case he is satisfied that the application was
                  in fact submitted by the candidate to the Institution in time
                  but could not reach the National Council’s office before the
                  notified date on account of failure of authorities in the
                  Institution to do so.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>4.5.2</strong> The National Council will accept no
                  application, which has not been so submitted or is not
                  complete in every respect. The Head of the Institution shall
                  be competent to refuse to forward to the National Council any
                  such application, which is not complete in every respect.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>4.5.3</strong> Notwithstanding the forwarding of
                  applications for admission to examination and payment of
                  examination fee through the Head of Institution and allocation
                  of Examination Roll Numbers by the National Council, the Head
                  of the Institution shall be competent to withdraw such
                  applications in respect of those candidates who do not fulfil
                  any of the conditions laid down under para 4.1.3 (a) and (b)
                  above, at any time National Council for Hotel Management &amp;
                  Catering Technology, Noida. before the commencement of the
                  examination and to disallow those candidates from appearing
                  for the examination.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>4.6</strong> Students who wish to continue their
                  studies, after having been out of the institute for not more
                  than two years due to any reason, except those who for
                  adopting unfair means on found guilty for gross indiscipline
                  etc. and having been debarred by the competent authority from
                  appearing in the examination conducted by the National
                  Council, could do so within one week from the commencement of
                  the session. The period of absence shall be counted from
                  session to session and not from the date candidate leaves the
                  institution. Attendance for such candidates shall be counted
                  from the date of commencement of session.
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  {" "}
                  <strong>4.7</strong> No candidate will be allowed to be on the
                  rolls of any institute if he/she fails to clear the whole or
                  any part of each year of the course within three academic
                  years/sessions. Failure to clear the subject(s) in the third
                  and last year/session will in effect result in the candidate
                  being out of the system. Such candidates desirous to continue
                  the course will have to seek fresh admission in the first year
                  of the course as per the manner prescribed therefore by the
                  National Council. Also no candidate for the three-year degree
                  programme will be allowed to remain on the rolls of any
                  institute for over a period of five years within which time
                  he/she will have to qualify. Candidates failing to do so will
                  be relieved of their enrollment.
                </p>
              </div>
              <h1 className="text-center">Grooming</h1>
              <div className="rulecontain">
                <h3>Dress &amp; Grooming Code</h3>
                <p>
                  To create a disciplined environment and maintain a
                  professional interface with the industry, a dress and Grooming
                  code for all students of IHM Kolkata has been formulated with
                  inputs from hoteliers, faculty, alumni and present students.
                  It will remain mandatory for all students to wear the
                  prescribed uniform in their respective practical and theory
                  classes. For the remaining time in the Institute all students
                  are advised to strictly follow dress code as mentioned below:
                </p>
              </div>
              <div className="rulecontain">
                <p>
                  <strong> For Boys:</strong> 
                </p>
                <ul>
                  <li className="li-none">
                    » General Uniform (Black Trousers, White Shirt Full Sleeves
                    and Uniform Tie). No other dress will be allowed. Entering
                    Institute with Jeans &amp; T shirts is not allowed even if
                    there is no class.
                  </li>
                  <li className="li-none">
                    » The shirt should be tucked in. The sleeves of a full
                    sleeve shirt should never be rolled up.
                  </li>
                  <li className="li-none">
                    » Formal shoes (Oxford black) and black socks.
                  </li>
                  <li className="li-none">
                    » No visible painting and tattoos.
                  </li>
                  <li className="li-none">
                    » No Ornament eg. Ear rings, bangles except for religious
                    purpose are allowed in the Institute.
                  </li>
                  <li className="li-none">
                    » Any kind of hair dye and spike hair style is strictly
                    prohibited.
                  </li>
                  <li className="li-none">
                    » In winter black coat (final year) and black pullover for
                    1st and 2nd year (V neck andwithout any design).
                  </li>
                  <li className="li-none">
                    » Wearing of Name plate is mandatory for all students during
                    college hours.
                  </li>
                </ul>
              </div>
              <div className="rulecontain">
                <p>
                  <strong> For Girls:</strong> 
                </p>
                <ul>
                  <li className="li-none">
                    » General Uniform (Black Trousers, White Shirt Full Sleeves
                    and Black Scarf). No other dress will be allowed. Entering
                    Institute with Jeans &amp; T shirts is not allowed even if
                    there is no class.
                  </li>
                  <li className="li-none">
                    » The shirt should be tucked in. The sleeves of shirt should
                    never be rolled up.
                  </li>
                  <li className="li-none">
                    » Formal Oxford black shoes with ankle high socks for Food
                    production and Ballerina shoes and black ankle high socks
                    for rest of the classes.
                  </li>
                  <li className="li-none">
                    » No visible painting including mehendi and tattoos.
                  </li>
                  <li className="li-none">» Only one ear ring/top.</li>
                  <li className="li-none">
                    » In winter black coat (final year) and black cardigan
                    without collar (with sari and black pullover with V neck and
                    without any design with general uniform) for 1st and 2nd
                    year.
                  </li>
                  <li className="li-none">
                    » In winter black coat (final year) and black pullover for
                    1st and 2nd year (V neck andwithout any design).
                  </li>
                  <li className="li-none">
                    » Any kind of hair dye is strictly prohibited. Hair should
                    be tied in Bun shape during college hours.
                    <br></br>
                    Observing the dress/grooming code is mandatory for all
                    students even during their visit to Institute on vacation/IT
                    for any official work, failing which strict disciplinary
                    steps would be initiated. For any clarification on the above
                    points, students can meet the Proctors, HODs or undersigned,
                  </li>
                </ul>
              </div>
              <div className="rulecontain">
                <p>
                  <strong>
                    MOBILE PHONE IS STRICTLY PROHIBITED IN CAMPUS DURING COLLEGE
                    DAYS. USE OF MOBILE PHONE MAY ATTARCT HEAVY FINE OF Rs
                    1000/- ON FIRST INSTANT AND REPEATATION WILL ATTRACT
                    CONFISCATION.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Rules;
