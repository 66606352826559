import React from "react";

import banner1 from "../assets/images/newGallery/galleryImg6.jpg";
import banner2 from "../assets/images/newGallery/galleryImg3.jpeg";
import banner3 from "../assets/images/newGallery/galleryImg4.jpeg";
import banner4 from "../assets/images/newGallery/galleryImg.jpg";
import banner5 from "../assets/images/newGallery/galleryImg2.png";
import banner6 from "../assets/images/newGallery/galleryImg5.jpeg";

import { ReactPhotoCollage } from "react-photo-collage";

const setting = {
  width: "100%",
  height: ["400px", "350px"],
  layout: [2, 4],
  photos: [
    { source: banner1, alt: "IHM Info" },
    { source: banner2, alt: "IHM Info" },
    { source: banner3, alt: "IHM Info" },
    { source: banner4, alt: "IHM Info" },
    { source: banner5, alt: "IHM Info" },
    { source: banner6, alt: "IHM Info" },
  ],

  showNumOfRemainingPhotos: true,
};
function Gallery() {
  return (
    <div className="container">
      <div className="row ">
        <div className=" gallery-container">
          <h1>IHMs Gallery</h1>

          <p className="page-description text-center">
            Institutes of Hotel Management
          </p>
        </div>
        <div className="gallery-box">
          <ReactPhotoCollage {...setting} />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
